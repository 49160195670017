import React, {useEffect, useState} from "react";
import {IRoute} from "router/config";
import {unwrapResult} from '@reduxjs/toolkit'
import {useSelector, useDispatch} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {Alert, Form, Input, Button, Card, Tabs} from 'antd';
import {LoginOutlined} from "@ant-design/icons";
import LayoutAuth from "layouts/LayoutAuth";
import {FiMail, FiLock} from "react-icons/fi";
import "./Signin.scss";
import logoShort from "assets/img/logo.svg";

import {
    signin
} from '../../redux/authenticationSlice';
import {RootState} from "common/store";

const {TabPane} = Tabs;

interface IProps {
    routes: IRoute[];
}

const Signin: React.FC<IProps> = () => {
    const signinStatus = useSelector((state: RootState) => state.authentication.status)
    // const error = useSelector((state: RootState) => state.authentication.error)
    const [signinError, setSigninError] = useState("");
    const [activeTab] = useState("1");
    const dispatch = useDispatch()
    const history = useHistory();

    const onSubmitSignin = async (values: any) => {
        try {
            const resultAction: any = await dispatch(
                signin(values)
            );
            unwrapResult(resultAction);
            history.push("/");
        } catch (e) {
            setSigninError(e.message);
        }
    };

    const onSubmitSigninFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <LayoutAuth>
            <div className="auth-signin">
                <Link to={"/"}>
                    <div className="logoContainer">
                        <img
                            src={logoShort}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                </Link>
                <Tabs defaultActiveKey={activeTab} centered>
                    <TabPane tab={<h2 className="text-center halfwidth">Sign in</h2>} key="1">
                        <Card bordered={false}>
                            <p className="text-center my-4">Sign in to your account</p>
                            <Form
                                layout="vertical"
                                size="large"
                                name="signin"
                                onFinish={onSubmitSignin}
                                onFinishFailed={onSubmitSigninFailed}
                            >
                                <Form.Item
                                    //label="Username"
                                    name="username"
                                    rules={[{required: true, type: 'email', message: 'Please input a valid email'}]}
                                >
                                    <Input
                                        prefix={<FiMail/>}
                                        placeholder="Email"
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-0"
                                    //label="Password"
                                    name="password"
                                    rules={[{required: true, message: 'Please input a password'}]}
                                >
                                    <Input.Password
                                        prefix={<FiLock/>}
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                <p className="text-right mt-2">
                                    <Link to={"/account/password/forgot"}>
                                        Forgot password?
                                    </Link>
                                </p>
                                <Form.Item
                                    className="text-center mb-0">
                                    <Button
                                        loading={signinStatus === 'loading'}
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        className="mb-3"
                                        icon={<LoginOutlined/>}
                                    >
                                        Sign in
                                    </Button>
                                </Form.Item>
                                {signinError &&
                                <Alert message={signinError} type="error" showIcon/>
                                }
                            </Form>
                            {/*<Divider plain>or</Divider>
                            <div className="text-center">
                                <Card bordered={false}>
                                    <Button type="link" size="large" onClick={() => history.push('/license/new')}>Register
                                        and Activate License</Button>
                                </Card>
                            </div>*/}
                        </Card>
                    </TabPane>
                </Tabs>
            </div>
        </LayoutAuth>
    );
};

export default Signin;
