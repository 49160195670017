import api from "helpers/apiService";
import {License, NewLicense, LicenseCheck, NewUser, Canvas} from "../interfaces";
import {User} from "../../../models/user.interface";

export const licenseService = {
  checkLicense,
  getLicenses,
  postLicense,
  getUsers,
  getCanvas,
  postCanvas,
  editCanvas,
  resetCanvasGuestPin,
  resetCanvasModeratorPin,
  postUser,
  deleteUser,
  deleteCanvas,
};

async function checkLicense(licenseCheck: LicenseCheck): Promise<LicenseCheck> {
  const resp = await api().post(`api/License/CheckLicense`, licenseCheck);
  return resp.data;
}

async function getLicenses(): Promise<License[]> {
  const resp = await api().get(`api/License`);
  return resp.data;
}

async function postLicense(license: NewLicense): Promise<NewLicense> {
  const resp = await api().post(`api/License/CreateCompany`, license);
  return resp.data as NewLicense;
}

async function getUsers(): Promise<User[]> {
  const resp = await api().get(`api/License/User`);
  return resp.data;
}

async function getCanvas(): Promise<Canvas[]> {
  const resp = await api().get(`api/Service/GetAllMeetings`);
  return resp.data;
}

async function postUser(user: NewUser): Promise<NewUser> {
  const resp = await api().post(`api/License/User`, user);
  return resp.data as NewUser;
}

async function postCanvas(canvas: Canvas): Promise<Canvas> {
  const resp = await api().post(`api/Service/AddPlannedMeeting`, canvas);
  return resp.data as Canvas;
}

async function editCanvas(canvas: Canvas): Promise<Canvas> {
  const resp = await api().post(`api/Service/UpdateMeeting`, canvas);
  return resp.data as Canvas;
}

async function resetCanvasGuestPin(canvasId: string): Promise<any> {
  const resp = await api().post(`api/Service/ResetGuestPin/${canvasId}`);
  return resp.data;
}

async function resetCanvasModeratorPin(canvasId: string): Promise<any> {
  const resp = await api().post(`api/Service/ResetModeratorPin/${canvasId}`);
  return resp.data;
}

async function deleteUser(userId: string): Promise<NewUser> {
  const resp = await api().delete(`api/License/User/${userId}`);
  return resp.data;
}

async function deleteCanvas(canvasId: string): Promise<any> {
  const resp = await api().delete(`api/Service/RemoveMeeting/${canvasId}`);
  return resp.data;
}
