import React from "react";
import "./LayoutCentered.scss";
import logoShort from "../assets/img/logo.svg";

export default function LayoutCentered({children, ...props}: any) {

    return (
        <div className={props.large ? "wrapperCentered large" : "wrapperCentered"}>
            <div className="container">
                {children}
                <div className="text-center mt-5 p-2">
                    <h6 className="mb-0">Powered by</h6>
                    <img
                        src={logoShort}
                        alt="Logo"
                        className="logo logoFooter"
                    />
                </div>
            </div>
        </div>
    );
};
