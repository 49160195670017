import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
// @ts-ignore
import QrCode from "react-qr-code";
import {DeleteOutlined, EllipsisOutlined, MoreOutlined, SettingOutlined} from '@ant-design/icons';

import {
    Row,
    Col,
    Card,
    Button,
    Form,
    Typography,
    message, Menu, Input, Modal, Tooltip, Popconfirm, Dropdown
} from 'antd';
import {
    ShareAltOutlined,
    LeftOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import {RootState} from "common/store";
import {
    deleteCanvas,
    getCanvas,
    postCanvas,
    editCanvas,
    resetCanvasGuestPin,
    resetCanvasModeratorPin,
} from "modules/license/redux/licenseSlice";
import {Canvas, NewUser} from "modules/license/interfaces";
import "./BoardList.scss";
import {unwrapResult} from "@reduxjs/toolkit";
import {User} from "../../../../models/user.interface";
import {LoadingOutlined, LockOutlined, MobileOutlined, QrcodeOutlined, UserOutlined} from "@ant-design/icons/lib";

interface IProps {
    routes: IRoute[];
}

const {Text} = Typography;

const BoardList: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const [canvasForm] = Form.useForm();
    const [canvasEditForm] = Form.useForm();
    const [showNewCanvasModal, setShowNewCanvasModal] = useState(false);
    const [showEditCanvasModal, setShowEditCanvasModal] = useState(false);
    const [editCanvasCurrentItem, setEditCanvasCurrentItem] = useState<Canvas|undefined>(undefined);
    const [showDeleteCanvasModal, setShowDeleteCanvasModal] = useState(false);
    const [deleteCanvasCurrentItem, setDeleteCanvasCurrentItem] = useState<Canvas>({} as Canvas);
    const [qrLoading, setQrloading] = useState<string[]>([]);
    const [currentCanvasLink, setCurrentCanvasLink] = useState();
    const qrLoadingRef = useRef(qrLoading);
    qrLoadingRef.current = qrLoading;

    const [shareModalVisible, setShareModalVisible] = useState(false);
    const me: User = useSelector((state: RootState) => state.user.me)
    const canvas: Canvas[] = useSelector((state: RootState) => state.license.canvas)
    const postCanvasStatus = useSelector((state: RootState) => state.license.postCanvasStatus)
    const editCanvasStatus = useSelector((state: RootState) => state.license.editCanvasStatus)
    const resetCanvasGuestPinStatus = useSelector((state: RootState) => state.license.resetCanvasGuestPinStatus)
    const resetCanvasModeratorPinStatus = useSelector((state: RootState) => state.license.resetCanvasModeratorPinStatus)
    const getCanvasStatus = useSelector((state: RootState) => state.license.getCanvasStatus)
    const deleteCanvasStatus = useSelector((state: RootState) => state.license.deleteCanvasStatus)

    const getShareLinkValue = (name: string | undefined, sharedLink: string | undefined, id: string | undefined, pin: string | undefined) => {
        let qrCodeValue = `${process.env.REACT_APP_RMS_API_BASEURL}?MEETINGID=${id}&PIN=${pin}`;
        let qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeValue}`;
        let appUrl = `<a href="${sharedLink}" target="_blank">${sharedLink}</a>`;
        let downloadUrl = `<a href="http://www.riccanvas.com/download.html" target="_blank">http://www.riccanvas.com/download.html</a>`;
        const value = `You have been invited to "${name}" RICC canvas.<br/><br/>` +
            `Join via app:<br/> ${appUrl} <br/>` +
            //`Join via browser:<br/> ${browserUrl} <br/>` +
            `Download the app:<br/> ${downloadUrl}<br/><br/>
            <img src="${qrCode}" alt="QR Code">`;
        return value;
    }

    const getShareLinkValueText = (name: string | undefined, sharedLink: string | undefined, id: string | undefined, pin: string | undefined) => {
        let appUrl = `${sharedLink}`;
        let downloadUrl = `http://www.riccanvas.com/download.html`;
        return `You have been invited to "${name}" RICC canvas. Join via app: ${appUrl} or Download the app: ${downloadUrl}`;
    }

    function handleActionMenuClick(item: any, canvas: Canvas) {
        switch (item.key) {
            case "edit":
                setEditCanvasCurrentItem(canvas);
                canvasEditForm.setFieldsValue({Subject: canvas.Subject})
                setShowEditCanvasModal(true);
                break;
            case "reset_guest_pin":
                resetGuestPin(canvas.MeetingId);
                break;
            case "reset_moderator_pin":
                resetModeratorPin(canvas.MeetingId);
                break;
            case "delete":
                setDeleteCanvasCurrentItem(canvas);
                setShowDeleteCanvasModal(true);
                break;
            default: break;
        }
    }

    async function removeCanvas(canvasId: string) {
        try {
            const resultAction: any = await dispatch(
                deleteCanvas(canvasId)
            );
            unwrapResult(resultAction);
            message.success("Canvas deleted");
            setDeleteCanvasCurrentItem({} as Canvas);
            setShowDeleteCanvasModal(false);
            dispatch(getCanvas());
        } catch (e) {
            message.error(e.message);
            setShowDeleteCanvasModal(false);
        }
    }

    async function resetGuestPin(canvasId: string) {
        try {
            const resultAction: any = await dispatch(
                resetCanvasGuestPin(canvasId)
            );
            unwrapResult(resultAction);
            message.success("Canvas Guest PIN reset");
            dispatch(getCanvas());
        } catch (e) {
            message.error(e.message);
        }
    }

    async function resetModeratorPin(canvasId: string) {
        try {
            const resultAction: any = await dispatch(
                resetCanvasModeratorPin(canvasId)
            );
            unwrapResult(resultAction);
            message.success("Canvas Moderator PIN reset");
            dispatch(getCanvas());
        } catch (e) {
            message.error(e.message);
        }
    }

    async function createCanvas(values: any) {
        const newCanvas = {
            ...values,
            Organizer: {
                FullName: me.firstName + " " + me.lastName,
                Email: me.email
            }
        } as Canvas;
        try {
            const resultAction: any = await dispatch(
                postCanvas(newCanvas)
            );
            unwrapResult(resultAction);
            message.success("New Canvas added!");
            setShowNewCanvasModal(false);
            canvasForm.resetFields();
            dispatch(getCanvas());
        } catch (e) {
            message.error(e.message);
        }
    }

    async function updateCanvas(values: any) {
        const editedCanvas = {
            MeetingId: editCanvasCurrentItem?.MeetingId,
            ...values,
        } as Canvas;
        try {
            const resultAction: any = await dispatch(
                editCanvas(editedCanvas)
            );
            unwrapResult(resultAction);
            message.success("Canvas updated!");
            setShowEditCanvasModal(false);
            canvasEditForm.resetFields();
            dispatch(getCanvas());
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect(() => {
        dispatch(getCanvas());
    }, [dispatch])

    function renderList() {
        if (getCanvasStatus === "loading" || deleteCanvasStatus === "loading") {
            return <div className="p-5 mx-auto"><LoadingOutlined/></div>;
        }
        if (canvas.length) {
            return canvas.map((canvas: Canvas) => {
                return (
                    <Col xs={24} sm={12} className="my-2" key={canvas.MeetingId}>
                        <Card
                            bordered
                            className="shadow-lg"
                            title={
                                <div className="card-title">
                                    <h3 className="mb-0 font-bold">{canvas.Subject}</h3>
                                    <h4 className="mb-0 font-sans">{canvas.ShortId}</h4>
                                </div>
                            }
                            actions={[
                                <Tooltip placement="bottom" title="Open in app">
                                    <a href={canvas.SharedLinkModerator || canvas.SharedLink} target="_blank">
                                        <Button
                                            loading={qrLoading.includes(canvas.MeetingId)}
                                            icon={<MobileOutlined
                                                style={{fontSize: '1.5rem', color: '#333333'}}/>}
                                            type="link"/>
                                    </a>
                                </Tooltip>,
                                <Tooltip placement="bottom" title="Show QR Code">
                                    <Button
                                        onClick={() => {
                                            setCurrentCanvasLink(`${process.env.REACT_APP_RMS_API_BASEURL}?MEETINGID=${canvas.ShortId}&PIN=${canvas.ModeratorPin}`);
                                            setShareModalVisible(true);
                                        }}
                                        loading={qrLoading.includes(canvas.MeetingId)}
                                        icon={<QrcodeOutlined
                                            style={{fontSize: '1.5rem', color: '#333333'}}/>}
                                        type="link"/>
                                </Tooltip>,
                                <Tooltip placement="bottom" title="Share link">
                                    <ShareAltOutlined
                                        onClick={() => {
                                            const textHtml = getShareLinkValue(canvas.Subject, canvas.SharedLink, canvas.ShortId, canvas.ModeratorPin)
                                            const textPlain = getShareLinkValueText(canvas.Subject, canvas.SharedLink, canvas.ShortId, canvas.ModeratorPin)
                                            if (navigator.clipboard && window.isSecureContext) {
                                                // @ts-ignore
                                                const clipboardItem = new ClipboardItem({
                                                    "text/plain": new Blob(
                                                        [textPlain],
                                                        { type: "text/plain" }
                                                    ),
                                                    "text/html": new Blob(
                                                        [textHtml],
                                                        { type: "text/html" }
                                                    ),
                                                });
                                                // @ts-ignore
                                                navigator.clipboard.write([clipboardItem]).then(() => {
                                                    message.success("Join Link copied to the clipboard!");
                                                },() => {
                                                    message.error("Error copying the link. Please try again.");
                                                });
                                            } else {
                                                const textArea = document.createElement("textarea");
                                                textArea.value = textPlain;
                                                textArea.style.position = "absolute";
                                                textArea.style.left = "-999999px";
                                                document.body.prepend(textArea);
                                                textArea.select();
                                                try {
                                                    document.execCommand('copy');
                                                } catch (error) {
                                                    message.error("Error copying the link. Please try again.");
                                                } finally {
                                                    textArea.remove();
                                                    message.success("Join Link copied to the clipboard!");
                                                }
                                            }
                                        }}
                                        style={{fontSize: '1.5rem', color: '#333333'}}
                                        key="share"/>
                                </Tooltip>,
                                <Tooltip placement="bottom" title="Actions">
                                    <Dropdown overlay={<Menu onClick={(item) => handleActionMenuClick(item, canvas)}>
                                        <Menu.Item key="edit">
                                            Edit title
                                        </Menu.Item>
                                        <Menu.Item key="reset_guest_pin">
                                            Reset Guest PIN
                                        </Menu.Item>
                                        <Menu.Item key="reset_moderator_pin">
                                            Reset Moderator PIN
                                        </Menu.Item>
                                        <Menu.Item key="delete">
                                            <Text type="danger">Delete</Text>
                                        </Menu.Item>
                                    </Menu>} placement="topRight" arrow>
                                        <MoreOutlined
                                            style={{fontSize: '1.5rem', color: '#333333'}}
                                            key="ellipsis"/>
                                    </Dropdown>
                                </Tooltip>,
                            ]}>
                            <Row className="">
                                <Col xs={24} sm={12} className="mb-2">
                                    <h6 className="mb-0 uppercase"><UserOutlined/> Guest PIN</h6>
                                    <h3 className="mb-0 font-sans">{canvas.ClientPin}</h3>
                                </Col>
                                <Col xs={24} sm={12} className="mb-2">
                                    <h6 className="mb-0 uppercase"><LockOutlined/> Moderator PIN</h6>
                                    <h3 className="mb-0 font-sans">{canvas.ModeratorPin}</h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                )
            })
        }
        return (
            <div className="text-center p-5">
                No canvas created yet.
            </div>
        )
    }

    return (
        <LayoutCentered>
            <div className="board-list">
                <div className="board-header">
                    <Row align="middle" className="mb-0">
                        <Col xs={6}>
                            <Link to={"/"}>
                                <Button icon={<LeftOutlined/>}
                                        type="link" className="inline-block">Home</Button>
                            </Link>
                        </Col>
                        <Col xs={12} className="text-center">
                            <h3 className="ml-2 mb-0 inline-block font-bold">My Canvas List</h3>
                        </Col>
                        <Col xs={6} className="text-right">
                            <Button icon={<PlusOutlined/>}
                                    onClick={() => setShowNewCanvasModal(true)}
                                    type="primary" ghost className="inline-block">New</Button>
                        </Col>
                    </Row>
                    <Row gutter={12} className="mt-5">
                        {renderList()}
                    </Row>
                </div>
            </div>
            <Modal
                visible={showNewCanvasModal}
                onCancel={() => {
                    setShowNewCanvasModal(false);
                    canvasForm.resetFields();
                }}
                footer={null}
            >
                <Form
                    id="detailsForm"
                    form={canvasForm}
                    layout="vertical"
                    size="large"
                    name="license"
                    className="px-4 pb-2"
                    onFinish={(values: any) => {
                        createCanvas(values);
                    }}
                >
                    <Form.Item
                        label="Title"
                        name="Subject"
                        rules={[{type: "string", required: true, message: 'Required field'}]}>
                        <Input
                            size={'large'}
                            placeholder="Canvas title"
                        />
                    </Form.Item>
                    <Form.Item className="text-center mt-5">
                        <Row>
                            <Col xs={24} className="text-right">
                                <Button
                                    loading={postCanvasStatus === 'loading'}
                                    size="large"
                                    htmlType="submit"
                                    type="primary">
                                    Create Canvas
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={showEditCanvasModal}
                onCancel={() => {
                    setShowEditCanvasModal(false);
                    canvasEditForm.resetFields();
                }}
                footer={null}
            >
                <Form
                    id="detailsForm"
                    form={canvasEditForm}
                    layout="vertical"
                    size="large"
                    name="license"
                    className="px-4 pb-2"
                    onFinish={(values: any) => {
                        updateCanvas(values);
                    }}
                >
                    <Form.Item
                        label="Title"
                        name="Subject"
                        rules={[{type: "string", required: true, message: 'Required field'}]}>
                        <Input
                            size={'large'}
                            placeholder="Canvas title"
                        />
                    </Form.Item>
                    <Form.Item className="text-center mt-5">
                        <Row>
                            <Col xs={24} className="text-right">
                                <Button
                                    loading={editCanvasStatus === 'loading'}
                                    size="large"
                                    htmlType="submit"
                                    type="primary">
                                    Update Canvas
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                confirmLoading={deleteCanvasStatus === "loading"}
                title={deleteCanvasCurrentItem.Subject}
                visible={showDeleteCanvasModal}
                onOk={() => removeCanvas(deleteCanvasCurrentItem.MeetingId)}
                onCancel={() => {
                    setShowDeleteCanvasModal(false);
                    setDeleteCanvasCurrentItem({} as Canvas);
                }}
                okText={"Yes, delete"}
                cancelText={"No"}
            >
                <p>Are you sure you want to delete this canvas?</p>
            </Modal>
            <Modal
                centered
                width={360}
                bodyStyle={{padding: "48px 20px", margin: 0, textAlign: "center"}}
                visible={shareModalVisible}
                footer={null}
                destroyOnClose
                onOk={() => {
                    setShareModalVisible(false);
                }}
                onCancel={() => {
                    setShareModalVisible(false);
                }}
            >
                <QrCode size={256} level="L" value={currentCanvasLink}/>
                <h3 className="mt-5">Scan with a camera to connect</h3>
            </Modal>
        </LayoutCentered>
    );
};

export default BoardList;
