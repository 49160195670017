import React from "react";
import {IRoute} from "router/config";
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {Card, Col, Row, Avatar, Tag} from 'antd';
import servicesHelper from 'helpers/services';
import {RootState} from "common/store";
import {signout} from "modules/authentication/redux/authenticationSlice";
import {reset} from "modules/user/redux/userSlice";
import LayoutCentered from "layouts/LayoutCentered";
import "./Home.scss"
import {FiLogOut, FiKey, FiUsers, FiMonitor} from "react-icons/fi";
import {Company, User} from "models/user.interface";
import {UserOutlined} from "@ant-design/icons/lib";

interface IProps {
    routes: IRoute[];
}

const Home: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const user: User = useSelector((state: RootState) => state.user.me)
    const company: Company = useSelector((state: RootState) => state.user.company)

    const onClickLogout = () => {
        dispatch(reset());
        dispatch(signout());
        history.replace("/auth/signin");
    }

    return (
        <LayoutCentered>
            <div className="home-menu">
                <div className="home-header">
                    <Row align="middle" className="mb-0 text-center">
                        <Col xs={24} className="text-center">
                            <Avatar icon={<UserOutlined/>}
                                //src={servicesHelper.getUserAvatarSrc(user)}
                                    size={68}
                                    className="user-avatar"
                                    style={{marginTop: '-64px'}}/>
                            <h2 className="mt-3 font-bold mb-0 text-center">{company.companyLegalName ? company.companyLegalName : `${user.firstName} ${user.lastName}`}</h2>
                            <h4 className="text-center">{user.email}</h4>
                            {company.companyLegalName && <Tag color="#da023a">Administrator</Tag>}
                        </Col>
                    </Row>
                </div>
                <div className="home-list">
                    {company.companyLegalName ?
                    <div>
                        <Card
                            className="home-card"
                            bodyStyle={{padding: "15px 10px"}}
                            onClick={() => {
                                history.push("/license");
                            }}>
                            <h3 className="mb-0"><FiKey className="icoFix mx-2"/> License Keys</h3>
                        </Card>
                        <Card
                            className="home-card"
                            bodyStyle={{padding: "15px 10px"}}
                            onClick={() => {
                                history.push("/user");
                            }}>
                            <h3 className="mb-0"><FiUsers className="icoFix mx-2"/> Users</h3>
                        </Card>
                    </div>
                    : <Card
                            className="home-card"
                            bodyStyle={{padding: "15px 10px"}}
                            onClick={() => {
                                history.push("/canvas");
                            }}>
                            <h3 className="mb-0"><FiMonitor className="icoFix mx-2"/> My Canvas</h3>
                        </Card>
                    }
                    <Card
                        className="home-card"
                        bodyStyle={{padding: "15px 10px"}}
                        onClick={() => {
                            onClickLogout();
                        }}>
                        <h3 className="mb-0" style={{color: "red"}}><FiLogOut style={{color: "red"}}
                                                                              className="icoFix mx-2"/> Logout</h3>
                    </Card>
                </div>
            </div>
        </LayoutCentered>
    );
};

export default Home;
