//GLOBAL
import React from 'react';
import {Card} from 'antd';
import LayoutCentered from "../../../../layouts/LayoutCentered";
import logoShort from "../../../../assets/img/logo.svg";
import {Link} from "react-router-dom";

const PrivacyPage = () => {
    return (
        <LayoutCentered large>
            <div className="auth-signin">
                <Link to={"/"}>
                    <div className="logoContainer">
                        <img
                            src={logoShort}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                </Link>
                <Card style={{textAlign: "justify"}}>
                    <h4 className="text-center">SOFTWARE LICENSE AGREEMENT</h4>
                    <h3 className="text-center font-bold">Ricoh Integrated Collaboration Cloud</h3>
                    <h5 className="text-center">© 2019 Ricoh Company Ltd. All rights reserved.</h5>
                    <p>IMPORTANT NOTICE: PLEASE READ THIS AGREEMENT (the “Agreement") CAREFULLY BEFORE INSTALLING OR
                        USING THE SOFTWARE LISTED ABOVE (the "Software").</p>
                    <p>This Agreement is a legal agreement between you ("<strong>Licensee</strong>" or
                        "<strong>you</strong>"), and Ricoh Company, Ltd and/or its “Affiliates” (as defined below)
                        (collectively, "<strong>Ricoh</strong>"). Ricoh is willing to license the Software, which
                        includes the associated media, and printed or electronic documentation, if any
                        ("<strong>Documentation</strong>"), only upon the condition that you accept all of the terms
                        contained in this Agreement. For the purposes of this Agreement, “<strong>Affiliate</strong>”
                        means any entity that, from time to time during the term of this Agreement, Controls, is
                        Controlled by, or is under common Control with Ricoh Company, Ltd. “<strong>Control</strong>”
                        means the power, whether directly or indirectly (by ownership of stock, share capital, the
                        possession of voting power, contract or otherwise) to appoint and/or remove the majority of the
                        members of the governing body of Ricoh, or otherwise exercise control over Ricoh’s affairs and
                        policies.</p>
                    <p>BY INSTALLING OR USING THE SOFTWARE, OR CLICKING ON THE "ACCEPT" BUTTON BELOW, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT. YOU REPRESENT
                        AND WARRANT THAT THE PERSON ACCEPTING THIS AGREEMENT HAS FULL POWER AND AUTHORITY TO DO SO ON
                        BEHALF OF LICENSEE.</p>
                    <p>IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, RICOH IS UNWILLING TO LICENSE THE SOFTWARE TO
                        YOU AND YOU MUST DISCONTINUE THE USE OR INSTALLATION OF THE SOFTWARE IMMEDIATELY. IN THIS CASE
                        YOU MUST CEASE USING THE SOFTWARE AND RETURN THE MEDIUM ON WHICH THE SOFTWARE IS STORED, IF ANY,
                        AND ALL ACCOMPANYING DOCUMENTATION TO RICOH.</p>
                    <ol>
                        <li>
                            <p>Grant and scope of license.</p>
                            <ol>
                                <li>
                                    <p>In consideration of you agreeing to abide by the terms of this Agreement and
                                        subject to the payment of all relevant fees and charges, if any, Ricoh hereby
                                        grants you a limited, non-exclusive, personal, non-transferable, license to use
                                        the Software in machine-readable object code form only and the Documentation
                                        (“License”). This License and your use of the Software is further expressly
                                        limited to the number, volume, quantity or other usage metrics or limitations
                                        set forth in the order, certificate of purchase or other confirmation issued by
                                        Ricoh in connection with your acquisition of the Software (“<strong>Confirmation
                                            of Permitted Usage</strong>”). Depending upon the product, the usage metrics
                                        may be stated as a permitted number of users, seats, multi-functional printers,
                                        devices, personal computers or servers, or some other quantitative limitation.
                                        Unless the Documentation provides to the contrary, this Agreement governs any
                                        future releases, revisions, updates or enhancements to the Software. Unless
                                        terminated as provided herein or expressly set forth in the Confirmation of
                                        Permitted Usage, the duration of the License is perpetual.</p>
                                </li>
                                <li>
                                    <p>You agree to use the Software in accordance with the terms of this Agreement and
                                        any additional terms set out in the Confirmation of Permitted Usage.</p>
                                </li>
                                <li>
                                    <p>Subject to the Confirmation of Permitted Usage, You may: (a) install, load,
                                        execute, operate, perform, display and use the Software for your internal
                                        business processes and operations only; (b) make a reasonable number of copies
                                        of the Software for back-up purposes only, provided that this is</p>
                                    <p>necessary for the activities permitted by clause 1.1, and (c) use any
                                        Documentation in support of the use permitted under clause 1.1 and make copies
                                        of the Documentation as reasonably necessary for its lawful use.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Licensee’s undertakings.</p>
                            <ol>
                                <li>
                                    <p>Except as expressly set out in this Agreement or as otherwise required by
                                        applicable mandatory law without the possibility of contractual waiver or
                                        limitation, you agree and undertake:</p>
                                    <ol>
                                        <li>
                                            <p>not to copy the Software or Documentation, except where such copying is
                                                incidental to normal use of the Software or where it is reasonably
                                                necessary for the purpose of back-up or operational security;</p>
                                        </li>
                                        <li>
                                            <p>not to make any derivative works of the Software, nor adapt, vary,
                                                translate, modify, or make alterations to, the whole or any part of the
                                                Software nor permit the Software or any part of it to be combined with,
                                                or become incorporated in, any other programs;</p>
                                        </li>
                                        <li>
                                            <p>not to decompile, disassemble or reverse engineer the whole or any part
                                                of the Software except where required by the terms of an applicable open
                                                source software license, or by applicable law without the possibility of
                                                contractual waiver;</p>
                                        </li>
                                        <li>
                                            <p>not to rent, lease, sublicense, loan or transfer the Software and
                                                Documentation to any third party, or otherwise allow any third party to
                                                use the Software, without the prior written consent of Ricoh;</p>
                                        </li>
                                        <li>
                                            <p>not to distribute, host as a service, make available for timesharing or
                                                otherwise make available for the benefit of third parties (whether for a
                                                fee or otherwise) the Software, in whole or in part;</p>
                                        </li>
                                        <li>
                                            <p>to keep any authorized copies of the Software secure and to maintain
                                                accurate and up-to-date records of the number and locations of all
                                                copies of the Software;</p>
                                        </li>
                                        <li>
                                            <p>to supervise and control use of the Software and ensure that the Software
                                                is used by your employees in accordance with the terms of this
                                                Agreement;</p>
                                        </li>
                                        <li>
                                            <p>to include the copyright notice of Ricoh on all entire and partial copies
                                                of the Software in any form; and</p>
                                        </li>
                                        <li>
                                            <p>not to provide, or otherwise make available, the Software in any form, in
                                                whole or in part (including, but not limited to, program listings,
                                                object and source program listings, object code and source code) to any
                                                person other than your employees without prior written consent from
                                                Ricoh.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>You must permit Ricoh and its authorized representatives, at all reasonable times
                                        and on reasonable advance notice, to inspect and have access to any premises,
                                        and to the computer equipment located there, at which the Software or the
                                        Documentation is being kept or used, and any records kept pursuant to this
                                        Agreement, for the purpose of ensuring that you are complying with the terms of
                                        this Agreement.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Support &amp; Maintenance.</p>
                            <ol>
                                <li>
                                    <p>The first three years of support and maintenance for the Software
                                        (“<strong>Support</strong>”) is included in the license fee for the Software and
                                        shall commence on the earlier of the date you register, activate or</p>
                                    <p>first use the Software. Support contracts for additional years may be available
                                        for an additional fee by contacting Ricoh who will then arrange further support.
                                        Ricoh shall provide first and second level Support, with third level Support
                                        ultimately being provided by Re Mago.</p>
                                </li>
                                <li>
                                    <p>During the Support period you shall be entitled to receive such bug fixes,
                                        maintenance releases, point releases and updates for the Software (e.g., V1.0 to
                                        v1.1) as may be provided by Ricoh from time to time. This Agreement does not
                                        entitle you to upgrades of the Software (e.g., V2.5 to v3.0) or to any new
                                        products. Software installation, configuration and customization, as well as any
                                        on-site support, are not included within the Support and must be purchased
                                        separately. In addition, the following failures or problems with the Software
                                        are excluded from Support: (a) failures due to end user initiated alterations to
                                        the Software operating environment, such as changes to data files, networks or
                                        computing platforms; (b) failures due to faults in any end user supplied
                                        computer or operating environment on which the Software resides; (c) problems
                                        due to end user’s failure to implement currently available updates or upgrades;
                                        (d) failures due to modifications or alterations of the Software by any party
                                        other than Ricoh, and (e) failures due to Software customization, even if
                                        performed by Ricoh. Ricoh will use reasonable efforts to provide support in a
                                        professional manner, but Ricoh cannot guarantee response times, or that every
                                        question or problem you raise will be resolved to your satisfaction. This
                                        Agreement does not grant or imply any additional warranties or guarantees of
                                        Software performance or availability.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Warranty.</p>
                            <ol>
                                <li>
                                    <p>Ricoh warrants that the media on which the software is contained, if any
                                        (“<strong>Media</strong>”) will be free from defects in material or workmanship
                                        when used in accordance with this Agreement for a period of 12 months from the
                                        date that the Media is delivered to you. This limited warranty does not apply if
                                        the problem with the Media results from accident, abuse or misapplication of the
                                        Media and shall not extend to anyone other than the original user of the
                                        Software.</p>
                                </li>
                                <li>
                                    <p>Ricoh warrants that, for a period of 12 months from the date shown on your
                                        Confirmation of Permitted Usage (unless a longer period is otherwise required by
                                        mandatory applicable law), the Software will, when installed on the hardware
                                        and/or operating system for which it was designed, and properly used, operate
                                        substantially in accordance with the Documentation. Any “Third Party Programs”
                                        (as defined below) are excluded from this limited warranty, and alteration,
                                        damage or misuse of the Software, or use of the Software with other software,
                                        hardware, operating systems or configurations other than that for which it was
                                        designed, voids this warranty.</p>
                                </li>
                                <li>
                                    <p>If either of the warranties in clause 4.1 or 4.2 is breached, you must tell Ricoh
                                        as soon as possible. You must give Ricoh a reasonable time to fix the problem
                                        and (if necessary) to supply you with a corrected version of the Media or
                                        Software (as applicable). This will be done without any additional charge to
                                        you. If Ricoh is unable to do this within a reasonable time or Ricoh does not
                                        think that it is a sensible way to deal with the problem, then Ricoh may if it
                                        wishes elect to take back the Software and the Documentation and to refund to
                                        you all of the money which you have paid to Ricoh for use of the Software. If
                                        Ricoh decides to do this, then this will be the only remedy you are allowed in
                                        relation to breach of either of the warranties concerned and Ricoh will not have
                                        any other liability in relation to them.</p>
                                </li>
                                <li>
                                    <p>You acknowledge that the Software has not been developed to meet your individual
                                        requirements and that it is therefore your responsibility to ensure that the
                                        facilities and functions of the Software as described in the Documentation meet
                                        your requirements.</p>
                                </li>
                                <li>
                                    <p>This Agreement sets out the full extent of Ricoh's obligations and liabilities in
                                        respect of the supply of the Software and Documentation. <strong>EXCEPT FOR THE
                                            LIMITED WARRANTY PROVIDED HEREIN, YOU ACKNOWLEDGE THAT THE SOFTWARE,
                                            SERVICES, SUPPORT AND ANYTHING</strong></p>
                                    <p>ELSE SUPPLIED TO YOU UNDER THIS AGREEMENT ARE BEING PROVIDED “AS IS”, WITHOUT
                                        WARRANTY OF ANY KIND WHATSOEVER. RICOH EXPRESSLY DISCLAIMS ALL OTHER WARRANTIES,
                                        CONDITIONS, REPRESENTATIONS OR OTHER TERMS WHATSOEVER (WHETHER EXPRESS OR
                                        IMPLIED) INCLUDING, BUT NOT LIMITED TO, THE IMPLIED TERMS OF SATISFACTORY
                                        QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                                        NON-INFRINGEMENT. YOU ASSUME RESPONSIBILITY FOR THE SELECTION OF THE SOFTWARE TO
                                        ACHIEVE YOUR INTENDED RESULTS. RICOH DOES NOT WARRANT THAT THE SOFTWARE WILL
                                        MEET YOUR REQUIREMENTS; THAT ITS OPERATION WILL BE UNINTERRUPTED, SECURE OR
                                        ERROR-FREE; OR THAT ALL ERRORS OR DEFECTS WILL BE CORRECTED. TO THE EXTENT
                                        PROHIBITED BY APPLICABLE LAW, AS DEFINED IN SECTION 10.3, SOME OR ALL OF THE
                                        ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Ricoh’s liability.</p>
                            <ol>
                                <li>
                                    <p>UNLESS OTHERWISE REQUIRED BY APPLICABLE LAW WITHOUT THE POSSIBILITY OF
                                        CONTRACTUAL WAIVER OR LIMITATION AND SUBJECT TO CLAUSE 5.4, IN NO EVENT SHALL
                                        RICOH, ITS AFFILIATES OR SUPPLIERS, OR ITS OR THEIR OFFICERS, DIRECTORS,
                                        EMPLOYEES, REPRESENTATIVES OR AGENTS (COLLECTIVELY, THE “RICOH PARTIES”) BE
                                        LIABLE TO YOU (WHETHER FOR BREACH OF CONTRACT, NEGLIGENCE OR FOR ANY OTHER
                                        REASON) FOR ANY: (A) LOSS OF PROFITS, SALES, BUSINESS, ANTICIPATED SAVINGS OR
                                        REVENUES; (B) LOSS OF GOODWILL; (C) LOSS, DAMAGE OR ALTERATION OF DATA; (D) LOSS
                                        OF OR LOSS OF USE OF HARDWARE, SOFTWARE OR DATA, OR (E) INDIRECT, CONSEQUENTIAL,
                                        EXEMPLARY OR SPECIAL LOSS OR DAMAGES.</p>
                                </li>
                                <li>
                                    <p>IN ADDITION, UNLESS OTHERWISE REQUIRED BY APPLICABLE LAW WITHOUT THE POSSIBILITY
                                        OF CONTRACTUAL WAIVER OR LIMITATION, RICOH PARTIES’ TOTAL AGGREGATE LIABILITY
                                        UNDER THIS AGREEMENT AND IN RELATION TO THE SOFTWARE AND ANYTHING WHICH RICOH
                                        HAS DONE OR NOT DONE IN CONNECTION WITH THIS AGREEMENT (AND WHETHER THE
                                        LIABILITY ARISES BECAUSE OF BREACH OF CONTRACT, NEGLIGENCE OR FOR ANY OTHER
                                        REASON) SHALL BE LIMITED TO THE GREATER OF THE TOTAL AMOUNTS PAID AND PAYABLE BY
                                        YOU FOR THE USE OF THE SOFTWARE OR €100 EUROS.</p>
                                </li>
                                <li>
                                    <p>NOTWITHSTANDING THE FOREGOING, RICOH PARTIES’ LIABILITY FOR: (A) DEATH OR
                                        PERSONAL INJURY CAUSED BY THE NEGLIGENCE OF RICOH PARTIES’ OR THEIR AGENTS OR
                                        EMPLOYEES; (B) FRAUDULENT MISREPRESENTATION, OR (C) ANY OTHER LIABILITY THAT
                                        CANNOT BE EXCLUDED OR LIMITED BY MANDATORY APPLICABLE LAW; IS NOT EXCLUDED OR
                                        LIMITED BY THIS AGREEMENT, EVEN IF ANY OTHER TERM OF THIS AGREEMENT WOULD
                                        OTHERWISE SUGGEST THAT THIS MIGHT BE THE CASE.</p>
                                </li>
                                <li>
                                    <p>TO THE EXTENT PROHIBITED BY APPLICABLE LAW, AS DEFINED IN SECTION 10.3, SOME OR
                                        ALL OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Intellectual Property Rights; Third Party Programs.</p>
                            <ol>
                                <li>
                                    <p>You acknowledge that all intellectual property rights, title, and interest in the
                                        Software and Documentation throughout the world are owned by Ricoh or its third
                                        party licensors, that rights in the Software are licensed (not sold) to you, and
                                        that you have no rights in, or to, the Software, Documentation or anything else
                                        supplied to you under this Agreement other than limited License provided by this
                                        Agreement. Further, no right, title or interest to any trademark, service mark
                                        or trade names of Ricoh is granted by this Agreement. Without limiting the
                                        foregoing, Ricoh and/or its licensors retain all right, title, and interest in
                                        and to its or their intellectual property rights, including but not limited to:
                                        (a) all software code (source and object), functionality, technology, system or
                                        network architecture</p>
                                    <p>and user interfaces and all modifications thereto; (b) all ideas, trade secrets,
                                        inventions, patents, copyrights and other intellectual property rights with
                                        respect to the Software; (c) all evaluations, comments, ideas and suggestions
                                        made by you regarding the Software, whether or not those are incorporated into
                                        subsequent versions, and (d) any modifications or derivative works developed
                                        from Ricoh’s or its licensors’ intellectual property rights. You agree to treat,
                                        protect and maintain Ricoh’s intellectual property rights as strictly
                                        confidential.</p>
                                </li>
                                <li>
                                    <p>You acknowledge that the Software may contain third party software and
                                        intellectual property rights which are owned by Ricoh’s licensors.</p>
                                </li>
                                <li>
                                    <p>You acknowledge that the Software may contain software programs, code or
                                        libraries owned by third parties and/or licensed pursuant to one or more open
                                        source software licenses (“Third Party Programs”). Your use of such Third Party
                                        Programs is subject to additional terms and conditions which can be found in the
                                        installation text files or download media that Ricoh provides to you in
                                        connection with the Software. Only when expressly permitted by applicable law
                                        without the possibility of contractual waiver or required by the license terms
                                        of a Third Party Program, Ricoh waives the prohibition in Section 2.1 hereof
                                        against reverse engineering or decompiling, but only for the limited purpose
                                        required by the applicable license or law and not to create any software which
                                        is substantially similar to the Software. Notwithstanding anything herein to the
                                        contrary, all Third Party Programs are furnished by Ricoh without support, “as
                                        is” and without any warranties of any kind, express or implied.</p>
                                </li>
                                <li>
                                    <p>Except where otherwise expressly set out in this Agreement or by Ricoh, you
                                        acknowledge that you have no right to have access to the Software in source code
                                        form, in unlocked coding or otherwise in human readable form with comments.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>Termination.</p>
                            <ol>
                                <li>
                                    <p>If the Confirmation of Permitted Usage expressly provides for a fixed term
                                        License, the License is terminated at the end of the fixed term unless the
                                        parties agree in writing to renew it.</p>
                                </li>
                                <li>
                                    <p>Except as provided in Section 7.1, the term of this License is perpetual and it
                                        is effective until terminated. At Ricoh’s option and upon notice to you, this
                                        Agreement will terminate immediately if you fail to pay any fees or charges
                                        specified in your Confirmation of Permitted Usage or fail to comply with any of
                                        the material terms and conditions of this Agreement.</p>
                                </li>
                                <li>
                                    <p>Upon expiration of the term (if applicable) or termination of this Agreement, you
                                        must immediately stop using the Software and, at Ricoh’s option, delete, destroy
                                        or return the Software and all of its copies, and confirm to Ricoh when this has
                                        been done. Any terms of this Agreement that by their nature extend beyond the
                                        termination of this Agreement shall survive.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <ol>
                        <li>
                            <p>Contact Information.</p>
                            <ol>
                                <li>
                                    <p>Unless otherwise prohibited by applicable law without the possibility of
                                        contractual waiver or limitation, you agree to allow Ricoh to store and use your
                                        contact information, including names, phone numbers, and e-mail addresses,
                                        anywhere it does business. Such information will be processed and used in
                                        connection with our business relationship, and may be provided to contractors,
                                        resellers authorized by Ricoh, and assignees of Ricoh for uses consistent with
                                        their collective business activities, including communicating with you (for
                                        example, for processing orders, for providing services, for promotions, and for
                                        market research). You acknowledge that your contact information will be stored
                                        at data centers, which may, or may not be located in the country where you are
                                        located, and you consent to the processing and storage of your contact
                                        information in such location (including storage at data centers outside the
                                        European Economic Area, even if you are located within the EEA). Under</p>
                                    <p>protection of confidentiality agreements, Ricoh may transfer your contact
                                        information to third party processors to help Ricoh provide services and manage
                                        its relationship with you, including purchase and order fulfillment, credit card
                                        processing, providing marketing assistance and providing other customer
                                        services. You agree that in the event we undergo re-organisation or are sold to
                                        a third party, Ricoh may transfer the contact information to that re-organized
                                        entity or third party.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>General.</p>
                            <ol>
                                <li>
                                    <p>Ricoh may transfer, assign, sub-contract or otherwise dispose of this Agreement,
                                        or any of its rights or obligations arising under it, at any time during the
                                        term of the Agreement. Neither this Agreement nor the license to use the
                                        Software may be assigned or otherwise transferred by you except to (i) a wholly
                                        owned subsidiary or (ii) an affiliate that is controlled by or under common
                                        control with you and which, in either case, expressly agrees in writing to
                                        assume all obligations under this Agreement. No such permitted assignment shall
                                        release the original licensee from liability hereunder.</p>
                                </li>
                                <li>
                                    <p>You agree to comply with all applicable export and import laws and regulations
                                        applicable to the jurisdiction in which the Software was obtained and in which
                                        it is used. You also agree that you will not use the Software for any purposes
                                        prohibited by law, including, without limitation, the development, design,
                                        manufacture or production of missiles, nuclear, chemical or biological
                                        weapons.</p>
                                </li>
                                <li>
                                    <p>The United Nations Convention on Contracts for the International Sale of Goods
                                        does not apply to this Agreement. The governing law and jurisdiction for this
                                        Agreement will depend upon where you purchased or obtained the Software, as
                                        follows:</p>
                                    <ol>
                                        <li>
                                            <p>if you purchased or obtained the Software in the Americas, this Agreement
                                                shall be deemed made under the laws of the State of New York, USA,
                                                excluding the choice of law and conflict of law provisions, and any
                                                claim against Ricoh may be enforced or disputed only and exclusively in
                                                the courts of the State of New York,</p>
                                        </li>
                                        <li>
                                            <p>if you purchased or obtained the Software in Europe, the Middle East or
                                                Africa, this Agreement shall be deemed made under the laws of the
                                                England, excluding the choice of law and conflict of law provisions, and
                                                any claim against Ricoh may be enforced or disputed only and exclusively
                                                in the courts of England, or</p>
                                        </li>
                                        <li>
                                            <p>if you purchased or obtained the Software in the regions other than those
                                                provided in clauses (a) and (b) above, this Agreement shall be deemed
                                                made under the laws of Japan, excluding the choice of law and conflict
                                                of law provisions, and any claim against Ricoh may be enforced or
                                                disputed only and exclusively in the courts of Tokyo, Japan.</p>
                                            <p>To the extent permitted by local law, the parties hereto waive any right
                                                they may have to trial by jury.</p>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>Should any provision of this Agreement be held to be void, invalid, unenforceable
                                        or illegal by a court, the validity and enforceability of the other provisions
                                        will not be affected thereby, it being the intent of the parties that this
                                        Agreement shall be enforced to the full extent allowable under applicable law.
                                        Without limiting the foregoing, if any limitation or exclusion of liability is
                                        held by a court or tribunal of competent jurisdiction to be unenforceable as to
                                        a particular claim or cause of action, the parties intend that it shall
                                        nonetheless apply to the maximum extent permitted by applicable law to all other
                                        claims and causes of action.</p>
                                </li>
                                <li>
                                    <p>Failure of Ricoh to enforce any provision of this Agreement shall not be
                                        construed as a waiver of such provision or of the right to enforce such
                                        provision.</p>
                                </li>
                                <li>
                                    <p>You agree that this Agreement and any document expressly referred to in it
                                        (including the Confirmation of Permitted Usage) is the entire agreement between
                                        you and Ricoh concerning the Software and Documentation and supersedes all
                                        proposals or prior agreements, verbal or written, and any other communications
                                        between you and Ricoh relating to the Software and Documentation.</p>
                                </li>
                                <li>
                                    <p>No amendment to this Agreement shall be effective unless signed by a duly
                                        authorized representative of both parties.</p>
                                </li>
                                <li>
                                    <p>Ricoh will not be liable to you for any breach of this Agreement which arises
                                        because of any circumstances which Ricoh cannot reasonably be expected to
                                        control.</p>
                                </li>
                                <li>
                                    <p>If the Software is supplied on a demonstration, evaluation or similar
                                        not-for-resale (“NFR”) basis, you are granted a limited, non-exclusive license
                                        to use a copy of the Software under the terms of this Agreement for a limited
                                        time period only. BY YOUR USE OF THE NFR OR EVALUATION SOFTWARE, YOU ACKNOWLEDGE
                                        AND AGREE THAT YOUR RIGHT TO USE THE SOFTWARE WILL TERMINATE AFTER THE
                                        EVALUATION PERIOD AND THEREAFTER YOU WILL NOT BE ABLE TO USE THE SOFTWARE UNTIL
                                        YOU PURCHASE A COMMERCIAL LICENSE. ALL NFR OR EVALUATION COPIES ARE LICENSED “AS
                                        IS” WITH NO WARRANTIES OF ANY TYPE WHATSOEVER, AND NO SUPPORT AND
                                        MAINTENANCE.</p>
                                </li>
                                <li>
                                    <p>No term of this Agreement is enforceable by a person who is not a party to this
                                        Agreement, except that all of Ricoh Affiliates are direct and intended
                                        third-party beneficiaries of this Agreement and shall have the benefit of and
                                        the right to enforce all provisions of this Agreement which benefit and are
                                        enforceable by Ricoh.</p>
                                </li>
                                <li>
                                    <p>If there are discrepancies between the English version of this Agreement and any
                                        other language versions of this Agreement, the English version of this Agreement
                                        shall prevail unless otherwise required by local law without the possibility of
                                        contractual waiver or limitation.</p>
                                </li>
                                <li>
                                    <p>You acknowledge that the unauthorized disclosure or use of the Software or any
                                        related Documentation or of Ricoh’s intellectual property rights, or breach of
                                        your confidentiality undertaking in violation of this Agreement would cause
                                        irreparable injury to Ricoh for which remedies at law would be inadequate.
                                        Accordingly, Ricoh may seek immediate injunctive or other equitable relief in a
                                        court of competent jurisdiction in connection with any breach or alleged breach
                                        of the provisions of this Agreement.</p>
                                </li>
                                <li>
                                    <p>Nothing in this Agreement affects any statutory rights of consumers under
                                        applicable law that cannot be waived or limited by contract.</p>
                                </li>
                                <li>
                                    <p>If any government or authority imposes a duty, tax (other than income tax), levy
                                        or fee on this Agreement or the sale or use of the Software itself that is not
                                        otherwise provided for in the Confirmation of Permitted Usage, you agree to pay
                                        it when Ricoh invoices you.</p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p>© 2019 Ricoh Company Ltd. All rights reserved.</p>
                    <p>Section 3 [Support and Maintenance]</p>
                    <p>Option Three (Self Contained M+S Provision)</p>
                    <p>CONSENT TO GATHER AND TRANSMIT ANONYMOUS DIAGNOSTIC AND USE INFORMATION: TO ASSIST RICOH AND ITS
                        AUTHORIZED REPRESENTATIVES IN SUPPORTING YOU AND SERVICING YOUR DEVICES, YOU CONSENT TO THE
                        COLLECTION AND TRANSMISSION BY THIS SOFTWARE OF INFORMATION ABOUT YOUR COPIERS, PRINTERS AND
                        MULTI FUNCTIONAL DEVICES TO A RICOH REMOTE SERVER, WHICH MAY BE LOCATED OUTSIDE THE COUNTRY
                        WHERE YOUR DEVICES ARE LOCATED. THE INFORMATION MAY INCLUDE, BY WAY OF ILLUSTRATION AND NOT
                        LIMITATION: (I) IDENTIFICATION OF ANY NETWORKED OUTPUT DEVICE (E.G., MAKE, MODEL AND SERIAL
                        NUMBER), (II) DEVICE USAGE, METER AND DIAGNOSTIC INFORMATION (E.G., NUMBER OF PAGES PRINTED,
                        ERROR CODES, ETC.), AND</p>
                    <p>(III) VERIFICATION OF LICENSE COMPLIANCE. THIS SOFTWARE CANNOT AND DOES NOT COLLECT DOCUMENT
                        CONTENT OR PERSONALLY IDENTIFIABLE INFORMATION ABOUT USERS. RICOH RETAINS ALL RIGHT, TITLE AND
                        INTEREST IN THIS INFORMATION, WHICH IT MAY USE OR PROVIDE TO THIRD PARTIES AUTHORIZED BY RICOH
                        TO SERVICE YOUR DEVICES AND IN ORDER TO PROVIDE BETTER SERVICES TO YOU. RICOH MAY ALSO USE THIS
                        INFORMATION FOR ITS REGULAR BUSINESS PURPOSES INCLUDING BUT NOT LIMITED TO PRODUCT DEVELOPMENT,
                        RESEARCH AND MARKETING, SO LONG AS THE INFORMATION IS COLLECTED ANONYMOUSLY IN A FORM THAT DOES
                        NOT PERSONALLY IDENTIFY YOU.</p>
                </Card>
            </div>
        </LayoutCentered>
    );
}

export default PrivacyPage;
