import React, {ComponentType, ReactNode} from 'react';
import Signin from "modules/authentication/components/Signin/Signin";
import Home from "modules/room/components/Home/Home";
import LicenseList from "../modules/license/components/LicenseList/LicenseList";
import LicenseNew from "../modules/license/components/LicenseNew/LicenseNew";
import UserList from "../modules/license/components/UserList/UserList";
import BoardList from "../modules/license/components/BoardList/BoardList";
import PasswordForgotPage from "../modules/authentication/components/Password/Forgot";
import PasswordResetPage from "../modules/authentication/components/Password/Reset";
import PrivacyPage from "../modules/privacy/components/Privacy/Privacy";

export interface IRoute {
    path: string;
    exact: boolean;
    fallback: NonNullable<ReactNode> | null;
    component?: ComponentType<any>;
    routes?: IRoute[];
    redirect?: string;
    private?: boolean; //VALAREA USER AUTHENTICATED ROUTE
    session?: boolean; //ROOM SESSION ROUTE
    isGroup?: boolean; //ROOM IS GROUP
}

export const routes: IRoute[] = [
    {
        path: '/auth/signin',
        exact: true,
        component: Signin,
        fallback: <div>Loading...</div>
    },
    {
        path: '/',
        exact: true,
        private: true,
        component: Home,
        fallback: <div>Loading...</div>
    },
    {
        path: '/profile', //TODO
        exact: true,
        private: true,
        component: Home,
        fallback: <div>Loading...</div>
    },
    {
        path: '/license',
        component: LicenseList,
        exact: true,
        private: true,
        fallback: <div>Loading...</div>,
    },
    {
        path: '/license/new',
        component: LicenseNew,
        exact: true,
        private: false,
        fallback: <div> Loading... </div>
    },
    {
        path: '/user',
        component: UserList,
        exact: true,
        private: true,
        fallback: <div>Loading...</div>,
    },
    {
        path: '/canvas',
        component: BoardList,
        exact: true,
        private: true,
        fallback: <div>Loading...</div>,
    },
    {
        path: '/account/password/forgot',
        exact: true,
        component: PasswordForgotPage,
        fallback: <div>Loading...</div>
    },
    {
        path: '/account/password/reset',
        exact: true,
        component: PasswordResetPage,
        fallback: <div>Loading...</div>
    },
    {
        path: '/privacy',
        exact: true,
        component: PrivacyPage,
        fallback: <div>Loading...</div>
    },
];
