//GLOBAL
import React, {useEffect, useState} from 'react';
import {Button, Form, Input, message, Tabs, Card, Alert} from 'antd';
import {authenticationService} from "../../services";
import {MailOutlined, CheckCircleTwoTone, RollbackOutlined} from "@ant-design/icons";
import LayoutAuth from "../../../../layouts/LayoutAuth";
import logoShort from "../../../../assets/img/logo.svg";
import {Link} from "react-router-dom";
import {ExclamationCircleTwoTone, LoadingOutlined} from "@ant-design/icons/lib";
import {useQueryParam, StringParam} from "use-query-params";

const {TabPane} = Tabs;

const PasswordResetPage = () => {

    const [loading, setLoading] = useState(false);
    const [loadingTokenCheck, setLoadingTokenCheck] = useState(false);
    const [tokenError, setTokenError] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false);
    const [resetError, setResetError] = useState(undefined);
    const [resetPasswordStep, setResetPasswordStep] = useState(1);
    const [email, setEmail] = useQueryParam("email", StringParam);
    const [token, setToken] = useQueryParam("token", StringParam);
    const [resetForm] = Form.useForm();
    const [activeTab] = useState("1");

    const checkPasswordStrength = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback("Please insert a password");
            return false;
        }
        if (value.length < 8) {
            callback("Password must contain at least 8 characters");
            return false;
        }
        if (value === resetForm.getFieldValue('email')) {
            callback("Password must be different from your email");
            return false;
        }
        let re = /[0-9]/;
        if (!re.test(value)) {
            callback("Password must contain at least one number (0-9)");
            return false;
        }
        re = /[a-z]/;
        if (!re.test(value)) {
            callback("Password must contain at least one lowercase letter (a-z)");
            return false;
        }
        re = /[A-Z]/;
        if (!re.test(value)) {
            callback("Password must contain at least one uppercase letter (A-Z)");
            return false;
        }
        re = /[^a-zA-Z0-9\s]/;
        if (!re.test(value)) {
            callback("Password must contain at least one special character");
            return false;
        }
        if (resetForm.getFieldValue('password_confirmation') && value !== resetForm.getFieldValue('password_confirmation')) {
            callback("Passwords must be equal");
            return false;
        }
        callback();
        return true;
    };

    const checkPasswordMatch = (rule: any, value: any, callback: any) => {
        if (!value) {
            callback("Please insert a password");
            return false;
        }

        if (value !== resetForm.getFieldValue('password')) {
            callback("Passwords must be equal");
            return false;
        }
        callback();
        return true;
    };

    const handleSubmitResetPassword = (values: any) => {
        setLoading(true);
        const {password, password_confirmation} = values;
        authenticationService.passwordReset({email, token, password, password_confirmation}).then((resp: any) => {
            setLoading(false);
            setResetSuccess(true);
        }, (error: any) => {
            const errorResponse = error.response ? error.response : error;
            const errorMessage = (errorResponse && errorResponse.data && errorResponse.data.message) || (errorResponse && errorResponse.message) || errorResponse.statusText;
            setLoading(false);
            setResetError(errorMessage);
        })
    }

    useEffect(() => {
        if (!token || !email) {
            message.error('Invalid request');
            setLoadingTokenCheck(false);
            setTokenError('Invalid request');
        }
    }, [email, token])

    const renderResetPasswordSection = () => {
        if (resetPasswordStep === 1) {
            return (
                renderResetPasswordStepOne()
            )
        }

        if (resetPasswordStep === 2) {
            return (
                renderResetPasswordStepTwo()
            )
        }
    }

    const renderResetPasswordStepOne = () => {
        if (loadingTokenCheck) {
            return (
                <div>
                    <LoadingOutlined className="mb-3" style={{fontSize: '2rem'}}/><br/>
                    <Alert message={'Token check in progress...'} type={'info'}/>
                </div>
            )
        }

        if (tokenError) {
            return (
                <div>
                    <ExclamationCircleTwoTone twoToneColor="#dc3545" className="mb-3" style={{fontSize: '2rem'}}/>
                    <Alert message={tokenError} type={'error'}/><br/>
                </div>
            )
        }

        return renderResetPasswordStepTwo()
    }

    const renderResetPasswordStepTwo = () => {
        if (tokenError) {
            return (
                <div>
                    <ExclamationCircleTwoTone twoToneColor="#dc3545" className="mb-3" style={{fontSize: '2rem'}}/>
                    <Alert message={tokenError} type={'error'}/><br/>
                </div>
            )
        }

        if (resetError) {
            return (
                <div>
                    <ExclamationCircleTwoTone twoToneColor="#dc3545" className="mb-3" style={{fontSize: '2rem'}}/>
                    <Alert message={resetError} type={'error'}/><br/>
                </div>
            )
        }

        if (resetSuccess) {
            return (
                <div className="text-center">
                    <CheckCircleTwoTone twoToneColor="#28a745" className="mb-3" style={{fontSize: '2rem'}}/>
                    <h4>Congratulations!<br/>Your password has been reset successfully.</h4>
                    <Link to={"/"}>
                        <Button type={'primary'} size={'large'}>Take me to Sign In</Button>
                    </Link>
                </div>
            )
        }

        return (
            <Form
                form={resetForm}
                layout="vertical"
                onFinish={(values) => handleSubmitResetPassword(values)}
                name={"reset"}
                size={"large"}
                colon={false}>
                <Form.Item
                    name="password"
                    // @ts-ignore
                    rules={[{required: true, message: 'Required field'},
                        ({getFieldValue}) => ({
                            validator(rule, value, callback) {
                                return checkPasswordStrength(rule, value, callback)
                            },
                        }),]}>
                    <Input.Password
                        size={'large'}
                        placeholder="New password"
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item
                    name="password_confirmation"
                    // @ts-ignore
                    rules={[{required: true, message: 'Required field'},
                        ({getFieldValue}) => ({
                            validator(rule, value, callback) {
                                return checkPasswordMatch(rule, value, callback)
                            },
                        }),]}>
                    <Input.Password
                        size={'large'}
                        placeholder="New password confirmation"
                        disabled={loading}
                    />
                </Form.Item>
                <Form.Item>
                    <div className="text-center mt-4">
                        <Button type="primary" htmlType="submit" size={'large'}
                                loading={loading}>
                            Set new password
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        )
    }

    return (
        <LayoutAuth>
            <div className="auth-signin">
                <Link to={"/"}>
                    <div className="logoContainer">
                        <img
                            src={logoShort}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                </Link>
                <Tabs defaultActiveKey={activeTab} centered>
                    <TabPane tab={<h2 className="text-center halfwidth">Forgot Password</h2>} key="1">
                        <Card bordered={false}>
                            <p className="text-center my-4">To reset your password, please enter your email and follow
                                the instructions.</p>
                            {renderResetPasswordSection()}
                        </Card>
                    </TabPane>
                </Tabs>
            </div>
        </LayoutAuth>
    );
}

export default PasswordResetPage;
