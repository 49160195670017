import React, {useEffect, useState} from "react";
import debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {
    Row,
    Col,
    Divider,
    Avatar,
    Button,
    Spin,
    Input,
    AutoComplete,
    Badge,
    Table,
    Menu,
    Dropdown,
    Modal,
    Form, Popconfirm, message
} from 'antd';
import {
    LoadingOutlined,
    TeamOutlined,
    DeleteOutlined,
    LeftOutlined, UserOutlined, MoreOutlined, ReloadOutlined, UserAddOutlined, PlusOutlined,
} from "@ant-design/icons/lib";
import {FiChevronLeft, FiLock, FiMail} from "react-icons/fi";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import servicesHelper from "helpers/services";
import {RootState} from "common/store";
import {deleteUser, getLicenses, postLicense, postUser} from "modules/license/redux/licenseSlice";
import {License, NewUser} from "modules/license/interfaces";
import {User, UserDeserializer} from "models/user.interface";
import "./LicenseList.scss";
import moment from "moment";
import {unwrapResult} from "@reduxjs/toolkit";

interface IProps {
    routes: IRoute[];
}

const tableLoading = {
    spinning: true,
    indicator: <LoadingOutlined/>,
};

const LicenseList: React.FC<IProps> = () => {
        const dispatch = useDispatch()
        const history = useHistory();
        const [userForm] = Form.useForm();
        const [showNewUserModal, setShowNewUserModal] = useState(false);
        const [selectedLicenseKey, setSelectedLicenseKey] = useState<string>("");
        const licenses: License[] = useSelector((state: RootState) => state.license.licenses)
        const getLicensesState = useSelector((state: RootState) => state.license.getLicensesStatus)
        const deleteUserStatus = useSelector((state: RootState) => state.license.deleteUserStatus)
        const postUserStatus: string = useSelector((state: RootState) => state.license.postUserStatus)

        const menu = (
            <Menu style={{zIndex: 9999}}>
                <Menu.Item key="1" icon={<UserAddOutlined/>} onClick={() => setShowNewUserModal(!showNewUserModal)}>
                    Create new user
                </Menu.Item>
            </Menu>
        );

        const columns = [
            {
                title: 'Key',
                dataIndex: 'Key',
                render: (text: any, row: any, idx: any) => {
                    if (!row.ParentKey) {
                        return <div>
                            <Row align="middle">
                                <Col span={2}>
                                    <Badge status="success"/>
                                </Col>
                                <Col span={22}>
                                    <h4 className="mb-0">{text}</h4>
                                    <p className="mb-0 text-sm">Expires: {moment(row.ExpirationDate).format('LL')}</p>
                                </Col>
                            </Row>
                        </div>
                    } else {
                        return <div>
                            <Row align="middle">
                                <Col span={2}>
                                    <Badge status="default"/>
                                </Col>
                                <Col span={22}>
                                    <h4 className="mb-0">{text}</h4>
                                    <h5>This key has been renewed with Key<br/>{row.ParentKey}</h5>
                                </Col>
                            </Row>
                        </div>
                    }
                },
            },
            {
                title: 'Users',
                dataIndex: 'Key',
                render: (text: any, row: any, idx: any) => {
                    if (!row.ParentKey) {
                        return <div className="text-right">
                            <h4 className="mb-0"><TeamOutlined/><span
                                className="px-3">{row.Users.length} / {row.MaxUsers}</span></h4>
                        </div>
                    }
                    return ""
                }
            },
            {
                title: 'Actions',
                dataIndex: 'Key',
                render: (text: any, row: any, idx: any) => {
                    if (!row.ParentKey) {
                        return <div className="text-center" style={{zIndex: 9999}}>
                            <Dropdown overlay={menu} placement="bottomRight">
                                <Button icon={<MoreOutlined/>}>
                                </Button>
                            </Dropdown>
                        </div>
                    }
                    return "";
                },
            },
        ];

        const checkPasswordStrength = (rule: any, value: any, callback: any) => {
            if (!value) {
                callback("Please insert a password");
                return;
            }
            if (value.length < 8) {
                callback("Password must contain at least 8 characters");
                return;
            }
            let re = /[0-9]/;
            if (!re.test(value)) {
                callback("Password must contain at least one number (0-9)");
                return;
            }
            re = /[a-z]/;
            if (!re.test(value)) {
                callback("Password must contain at least one lowercase letter (a-z)");
                return;
            }
            re = /[A-Z]/;
            if (!re.test(value)) {
                callback("Password must contain at least one uppercase letter (A-Z)");
                return;
            }
            re = /[^a-zA-Z0-9\s]/;
            if (!re.test(value)) {
                callback("Password must contain at least one special character");
                return;
            }
            callback();
            return;
        };

        async function createUser(values: any) {
            const newUser = {
                ...values,
                Username: values.Email,
                LicenseKey: selectedLicenseKey,
                VisibleForSearch: true
            } as NewUser;
            try {
                const resultAction: any = await dispatch(
                    postUser(newUser)
                );
                unwrapResult(resultAction);
                message.success("New User added!");
                setShowNewUserModal(false);
                dispatch(getLicenses());
            } catch (e) {
                message.error(e.message);
            }
        }

        async function removeUser(userId: string) {
            try {
                const resultAction: any = await dispatch(
                    deleteUser(userId)
                );
                unwrapResult(resultAction);
                message.success("User deleted");
                dispatch(getLicenses());
            } catch (e) {
                message.error(e.message);
            }
        }

        useEffect(() => {
            dispatch(getLicenses());
        }, [dispatch])

        return (
            <LayoutCentered>
                <div className="license-list">
                    <div className="license-header">
                        <Row align="middle" className="mb-0">
                            <Col xs={6}>
                                <Link to={"/"}>
                                    <Button icon={<LeftOutlined/>}
                                            type="link" className="inline-block">Home</Button>
                                </Link>
                            </Col>
                            <Col xs={12} className="text-center">
                                <h3 className="ml-2 mb-0 inline-block font-bold">License Keys</h3>
                            </Col>
                            <Col xs={6} className="text-right">
                                {/*<Link to={"/license/new"}>
                                <Button icon={<PlusOutlined/>}
                                        type="primary" ghost className="inline-block">New</Button>
                            </Link>*/}
                            </Col>
                        </Row>
                    </div>
                    <Table
                        rowClassName="cursor-pointer"
                        className="bg-white"
                        columns={columns}
                        pagination={false}
                        dataSource={licenses}
                        loading={getLicensesState === 'loading' ? tableLoading : false}
                        bordered
                        rowKey="Key"
                        showHeader={false}
                        expandable={{
                            rowExpandable: record => !record.ParentKey,
                            expandRowByClick: true,
                            onExpand: (expanded, record) => {
                                // @ts-ignore
                                setSelectedLicenseKey(record.Key)
                            },
                            expandedRowRender: record => <p style={{margin: 0}}>{
                                record.Users.length ? record.Users
                                    .map((user: User, idx: number) => {
                                        user = UserDeserializer(user);
                                        return (
                                            <Row align="middle" className="my-0">
                                                <Col xs={20}>
                                                    <Row align="middle">
                                                        <Col xs={2}>
                                                            <Avatar
                                                                icon={<UserOutlined/>}
                                                                size={24}/>
                                                        </Col>
                                                        <Col className="pl-3" xs={22}>
                                                            <h4 className="mb-0">{user.firstName} {user.lastName}</h4>
                                                            <h5>{user.email}</h5>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xs={4} className="text-right">
                                                    <Popconfirm
                                                        title="Are you sure? This action cannot be undone."
                                                        onConfirm={() => removeUser(user.userId)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button loading={deleteUserStatus === user.userId} danger
                                                                size="small" icon={<DeleteOutlined/>}>
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                                {idx + 1 !== record.Users.length && <Divider className="my-2"/>}
                                            </Row>
                                        )
                                    }) : "No users added yet."}</p>,
                        }}
                    />
                </div>
                <Modal
                    visible={showNewUserModal}
                    onCancel={() => setShowNewUserModal(false)}
                    footer={null}
                >
                    <Form
                        id="detailsForm"
                        form={userForm}
                        layout="vertical"
                        size="large"
                        name="license"
                        className="px-4 pb-2"
                        onFinish={(values: any) => {
                            createUser(values);
                        }}
                    >
                        <Divider><h6 className="uppercase mt-2 text-gray-600">User Details</h6></Divider>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{type: "email", required: true, message: 'Required field'}]}>
                            <Input
                                size={'large'}
                                placeholder="Email"
                                disabled={postUserStatus === "loading"}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            //@ts-ignore
                            rules={[{required: true, type: "string", message: 'Required field'},
                                ({getFieldValue}) => ({
                                    validator(rule, value, callback) {
                                        return checkPasswordStrength(rule, value, callback)
                                    }
                                })]}>
                            <Input.Password
                                size={'large'}
                                placeholder="Password"
                                disabled={postUserStatus === "loading"}
                            />
                        </Form.Item>
                        <Form.Item
                            label="First Name"
                            name="FirstName"
                            rules={[{required: true, message: "Required field"}]}
                        >
                            <Input
                            />
                        </Form.Item>
                        <Form.Item
                            label="Last Name"
                            name="LastName"
                            rules={[{required: true, message: "Required field"}]}
                        >
                            <Input
                            />
                        </Form.Item>
                        <Form.Item className="text-center mt-5">
                            <Row>
                                <Col xs={24} className="text-right">
                                    <Button
                                        loading={postUserStatus === 'loading'}
                                        size="large"
                                        htmlType="submit"
                                        type="primary">
                                        Add User
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
            </LayoutCentered>
        );
    }
;

export default LicenseList;
