import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { licenseService } from "../services";
import {Canvas, License, LicenseCheck, NewLicense, NewUser} from "../interfaces";
import {User} from "../../../models/user.interface";

const initialState = {
  licenses: [] as License[],
  users: [] as User[],
  canvas: [] as Canvas[],
  checkLicenseStatus: "idle",
  getLicensesStatus: "idle",
  getCanvasStatus: "idle",
  postLicenseStatus: "idle",
  postUserStatus: "idle",
  postCanvasStatus: "idle",
  editCanvasStatus: "idle",
  resetCanvasGuestPinStatus: "idle",
  resetCanvasModeratorPinStatus: "idle",
  deleteUserStatus: "idle",
  getUsersStatus: "idle",
  deleteCanvasStatus: "idle",
  error: ""
};

export const checkLicense = createAsyncThunk(
    "license/checkLicense",
    async (licenseCheck: LicenseCheck) => {
      const result = await licenseService.checkLicense(licenseCheck);
      return result;
    }
);

export const getLicenses = createAsyncThunk("license/getLicenses", async () => {
  return await licenseService.getLicenses();
});

export const getUsers = createAsyncThunk("license/getUsers", async () => {
  return await licenseService.getUsers();
});

export const getCanvas = createAsyncThunk("license/getCanvas", async () => {
  return await licenseService.getCanvas();
});

export const postLicense = createAsyncThunk(
    "license/postLicense",
    async (license: NewLicense) => {
      const result = await licenseService.postLicense(license);
      return result;
    }
);

export const postUser = createAsyncThunk(
    "license/postUser",
    async (user: NewUser) => {
      const result = await licenseService.postUser(user);
      return result;
    }
);

export const postCanvas = createAsyncThunk(
    "license/postCanvas",
    async (canvas: Canvas) => {
      const result = await licenseService.postCanvas(canvas);
      return result;
    }
);

export const editCanvas = createAsyncThunk(
    "license/editCanvas",
    async (canvas: Canvas) => {
      const result = await licenseService.editCanvas(canvas);
      return result;
    }
);

export const resetCanvasGuestPin = createAsyncThunk(
    "license/resetCanvasGuestPin",
    async (canvasId: string) => {
      const result = await licenseService.resetCanvasGuestPin(canvasId);
      return result;
    }
);

export const resetCanvasModeratorPin = createAsyncThunk(
    "license/resetCanvasModeratorPin",
    async (canvasId: string) => {
      const result = await licenseService.resetCanvasModeratorPin(canvasId);
      return result;
    }
);

export const deleteUser = createAsyncThunk(
    "license/deleteUser",
    async (userId: string) => {
      const result = await licenseService.deleteUser(userId);
      return result;
    }
);

export const deleteCanvas = createAsyncThunk(
    "license/deleteCanvas",
    async (canvasId: string) => {
      const result = await licenseService.deleteCanvas(canvasId);
      return result;
    }
);

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    //CHECK LICENSE
    builder.addCase(checkLicense.pending, (state, action) => {
      state.checkLicenseStatus = "loading";
    });
    builder.addCase(checkLicense.fulfilled, (state, action) => {
      state.checkLicenseStatus = "succeeded";
    });
    builder.addCase(checkLicense.rejected, (state, action) => {
      state.checkLicenseStatus = "failed";
      state.error = action.payload as any;
    });
    //GET LICENSES
    builder.addCase(getLicenses.pending, (state, action) => {
      state.getLicensesStatus = "loading";
    });
    builder.addCase(getLicenses.fulfilled, (state, action) => {
      state.getLicensesStatus = "succeeded";
      state.licenses = action.payload;
    });
    builder.addCase(getLicenses.rejected, (state, action) => {
      state.getLicensesStatus = "failed";
      state.error = action.payload as any;
    });
    //POST LICENSE
    builder.addCase(postLicense.pending, (state, action) => {
      state.postLicenseStatus = "loading";
    });
    builder.addCase(postLicense.fulfilled, (state, action) => {
      state.postLicenseStatus = "succeeded";
    });
    builder.addCase(postLicense.rejected, (state, action) => {
      state.postLicenseStatus = "failed";
      state.error = action.payload as any;
    });
    //POST USER
    builder.addCase(postUser.pending, (state, action) => {
      state.postUserStatus = "loading";
    });
    builder.addCase(postUser.fulfilled, (state, action) => {
      state.postUserStatus = "succeeded";
    });
    builder.addCase(postUser.rejected, (state, action) => {
      state.postUserStatus = "failed";
      state.error = action.payload as any;
    });
    //POST CANVAS
    builder.addCase(postCanvas.pending, (state, action) => {
      state.postCanvasStatus = "loading";
    });
    builder.addCase(postCanvas.fulfilled, (state, action) => {
      state.postCanvasStatus = "succeeded";
    });
    builder.addCase(postCanvas.rejected, (state, action) => {
      state.postCanvasStatus = "failed";
      state.error = action.payload as any;
    });
    //EDIT CANVAS
    builder.addCase(editCanvas.pending, (state, action) => {
      state.editCanvasStatus = "loading";
    });
    builder.addCase(editCanvas.fulfilled, (state, action) => {
      state.editCanvasStatus = "succeeded";
    });
    builder.addCase(editCanvas.rejected, (state, action) => {
      state.editCanvasStatus = "failed";
      state.error = action.payload as any;
    });
    //RESET CANVAS GUEST PIN
    builder.addCase(resetCanvasGuestPin.pending, (state, action) => {
      state.resetCanvasGuestPinStatus = "loading";
    });
    builder.addCase(resetCanvasGuestPin.fulfilled, (state, action) => {
      state.resetCanvasGuestPinStatus = "succeeded";
    });
    builder.addCase(resetCanvasGuestPin.rejected, (state, action) => {
      state.resetCanvasGuestPinStatus = "failed";
      state.error = action.payload as any;
    });
    //RESET CANVAS MODERATOR PIN
    builder.addCase(resetCanvasModeratorPin.pending, (state, action) => {
      state.resetCanvasModeratorPinStatus = "loading";
    });
    builder.addCase(resetCanvasModeratorPin.fulfilled, (state, action) => {
      state.resetCanvasModeratorPinStatus = "succeeded";
    });
    builder.addCase(resetCanvasModeratorPin.rejected, (state, action) => {
      state.resetCanvasModeratorPinStatus = "failed";
      state.error = action.payload as any;
    });
    //DELETE USER
    builder.addCase(deleteUser.pending, (state, action) => {
      state.deleteUserStatus = "loading";
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.deleteUserStatus = "succeeded";
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.deleteUserStatus = "failed";
      state.error = action.payload as any;
    });
    //DELETE CANVAS
    builder.addCase(deleteCanvas.pending, (state, action) => {
      state.deleteCanvasStatus = "loading";
    });
    builder.addCase(deleteCanvas.fulfilled, (state, action) => {
      state.deleteCanvasStatus = "succeeded";
    });
    builder.addCase(deleteCanvas.rejected, (state, action) => {
      state.deleteCanvasStatus = "failed";
      state.error = action.payload as any;
    });
    //GET USERS
    builder.addCase(getUsers.pending, (state, action) => {
      state.getUsersStatus = "loading";
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.getUsersStatus = "succeeded";
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.getUsersStatus = "failed";
      state.error = action.payload as any;
    });
    //GET CANVAS
    builder.addCase(getCanvas.pending, (state, action) => {
      state.getCanvasStatus = "loading";
    });
    builder.addCase(getCanvas.fulfilled, (state, action) => {
      state.getCanvasStatus = "succeeded";
      state.canvas = action.payload;
    });
    builder.addCase(getCanvas.rejected, (state, action) => {
      state.getCanvasStatus = "failed";
      state.error = action.payload as any;
    });
  },
});

//export const {} = licenseSlice.actions;

export default licenseSlice.reducer;
