import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { authenticationService } from "../services";
import { sessionService } from "../../room/services/session";
import { GuestRoom } from "../../room/interfaces";
import { AuthData } from "models/authData.interface";

const initialState = {
  authData: {
    expires: localStorage.getItem("x-auth-token-expires") || "",
    token: localStorage.getItem("x-auth-token") || "",
    chatServerUrl: localStorage.getItem("chat-server") || "",
    userId: localStorage.getItem("userId") || "",
  } as AuthData,
  authenticatedUser: localStorage.getItem("x-auth-token-expires")
    ? localStorage.getItem("x-auth-token") &&
      new Date(localStorage.getItem("x-auth-token-expires") || "") > new Date()
    : false,
  guestToken: "",
  connectUserStatus: "idle",
  connectGuestStatus: "idle",
  getGuestMeetingStatus: "idle",
  guestActiveRoom: {} as GuestRoom,
  status: "idle",
  error: null as any,
};

export const signin = createAsyncThunk(
  "authentication/signin",
  async (values: { username: string; password: string }) => {
    return await authenticationService.signin(values);
  }
);

export const connectUser = createAsyncThunk(
  "authentication/connectUser",
  async (
    values: { meetingId: string; pin: string; name?: string } | null,
    { getState }
  ) => {
    return await sessionService.connect(values, getState);
  }
);

export const connectGuest = createAsyncThunk(
  "authentication/connectGuest",
  async (
    values: { meetingId: string; pin: string; name: string },
    { getState }
  ) => {
    return await sessionService.connect(values, getState);
  }
);

export const getGuestMeeting = createAsyncThunk(
  "authentication/getGuestMeeting",
  async (values, { getState }) => {
    return await sessionService.getGuestMeeting(getState);
  }
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    signedIn(state, action) {},
    signout(state) {
      localStorage.clear();
      // return logoutState;
      return initialState;
    },
    disconnectUserFromRoom(state){
      state.connectUserStatus = "idle";
      state.connectGuestStatus = "idle";
      state.guestActiveRoom= {} as GuestRoom;
      state.status = "idle";

    },
    logoutGuest(state) {
      localStorage.removeItem("meeting-server");
      localStorage.removeItem("guest-name");
      localStorage.removeItem("guest-room-id");
      localStorage.removeItem("guest-room-pin");
    },
    activateGuestRoom(state, action){
      //TODO REVIEW ME: the event never dipatched
      state.guestActiveRoom.WebConferenceActive = !!action;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signin.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(signin.fulfilled, (state, action) => {
      state.status = "succeeded";
      //ADD USER INFO IN LS
      state.authData = action.payload;

      localStorage.setItem("x-auth-token", action.payload.token);
      localStorage.setItem("x-auth-token-expires", action.payload.expires);
      localStorage.setItem("chat-server", action.payload.chatServerUrl);
      localStorage.setItem("userId", action.payload.userId);

      state.authenticatedUser =
        action.payload.token && new Date(action.payload.expires) > new Date();

      //REMOVE GUEST INFO IN LS
      // localStorage.removeItem("meeting-server");
      // localStorage.removeItem("guest-name");
      // localStorage.removeItem("guest-room-id");
      // localStorage.removeItem("guest-room-pin");
    });
    builder.addCase(signin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });
    //CONNECT SESSION USER
    builder.addCase(connectUser.pending, (state, action) => {
      state.connectUserStatus = "loading";
    });
    builder.addCase(connectUser.fulfilled, (state, action) => {
      state.connectUserStatus = "succeeded";
      state.guestToken = action.payload.Token;
      localStorage.setItem("room-session-token", action.payload.Token);
      localStorage.setItem("meeting-server", action.payload.MeetingServerUrl);

      // NEED TO set guest info for authenticated guest
      localStorage.setItem("guest-x-auth-token", action.payload.Token);
      localStorage.setItem("guest-name", action.meta?.arg?.name || "");
      localStorage.setItem("guest-room-id", action.meta?.arg?.meetingId || "");
      localStorage.setItem("guest-room-pin", action.meta?.arg?.pin || "");
    });
    builder.addCase(connectUser.rejected, (state, action) => {
      state.connectUserStatus = "failed";
      state.error = action.payload;
    });
    //CONNECT SESSION GUEST
    builder.addCase(connectGuest.pending, (state, action) => {
      state.connectGuestStatus = "loading";
    });
    builder.addCase(connectGuest.fulfilled, (state, action) => {
      state.connectGuestStatus = "succeeded";
      state.guestToken = action.payload.Token;
      localStorage.setItem("guest-x-auth-token", action.payload.Token);
      localStorage.setItem("meeting-server", action.payload.MeetingServerUrl);
      localStorage.setItem("guest-name", action.meta.arg.name);
      localStorage.setItem("guest-room-id", action.meta.arg.meetingId);
      localStorage.setItem("guest-room-pin", action.meta.arg.pin);
    });
    builder.addCase(connectGuest.rejected, (state, action) => {
      state.connectGuestStatus = "failed";
      state.error = action.payload;
    });
    //GET GUEST MEETING
    builder.addCase(getGuestMeeting.pending, (state, action) => {
      state.getGuestMeetingStatus = "loading";
    });
    builder.addCase(getGuestMeeting.fulfilled, (state, action) => {
      state.getGuestMeetingStatus = "succeeded";
      state.guestActiveRoom = action.payload?.Item1
        ? action.payload?.Item1
        : {};
    });
    builder.addCase(getGuestMeeting.rejected, (state, action) => {
      state.getGuestMeetingStatus = "failed";
      state.error = action.payload;
    });
  },
});

export const { signedIn, signout, logoutGuest, disconnectUserFromRoom, activateGuestRoom } = authenticationSlice.actions;

export default authenticationSlice.reducer;
