import React from "react";
import "./LayoutAuth.scss";

export default function LayoutAuth({children}: any) {

    return (
        <div className="wrapperAuth">
            <div className="container">
                {children}
            </div>
        </div>
    );
};
