export interface AuthData {
  expires: string;
  issued: string;
  token: string;
  tokenType: string;
  chatServerUrl: string;
  userId: string;
}

export function AuthDataDeserializer(input: any): AuthData {
  return {
    expires: input[".expires"] || "",
    issued: input[".issued"] || "",
    token: input.access_token || "",
    tokenType: input.tokenType || "",
    chatServerUrl: input.chatServerUrl || "",
    userId: input.userId || "",
  };
}
