//GLOBAL
import React, {useState} from 'react';
import {Button, Form, Input, message, Tabs, Card} from 'antd';
import {authenticationService} from "../../services";
import {MailOutlined, CheckCircleTwoTone, RollbackOutlined} from "@ant-design/icons";
import LayoutAuth from "../../../../layouts/LayoutAuth";
import logoShort from "../../../../assets/img/logo.svg";
import {Link} from "react-router-dom";

const {TabPane} = Tabs;

const PasswordForgotPage = () => {

    const [loading, setLoading] = useState(false);
    const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
    const [email, setEmail] = useState("");
    const [activeTab] = useState("1");

    const handleSubmitForgotPassword = (values: any) => {
        setLoading(true);
        const {email} = values;
        authenticationService.passwordForgot(email).then(
            resp => {
                message.success('Please check your inbox!');
                setForgotPasswordStep(2);
                setLoading(false);
                setEmail(email);
            }, error => {
                setLoading(false);
            })
    }

    const renderForgotPasswordSection = () => {
        if (forgotPasswordStep === 1) {
            return (
                renderForgotPasswordStepOne()
            )
        }

        if (forgotPasswordStep === 2) {
            return (
                renderForgotPasswordStepTwo()
            )
        }
    }

    const renderForgotPasswordStepOne = () => {
        return (
            <Form
                layout="vertical"
                onFinish={(values) => handleSubmitForgotPassword(values)}
                name={"reset"}
                size={"large"}
                initialValues={{"email": email}}
                colon={false}>
                <Form.Item
                    name="email"
                    rules={[{type: "email", required: true, message: 'Required field'}]}>
                    <Input
                        size={'large'}
                        placeholder="Email"
                        disabled={loading}
                    />
                </Form.Item>
                <div className="text-center">
                    <Button type="primary" htmlType="submit" size={'large'} className="mt-4"
                            icon={<MailOutlined/>}
                            loading={loading}>
                        Send me a link
                    </Button>
                </div>
            </Form>
        )
    }

    const renderForgotPasswordStepTwo = () => {
        return (
            <div style={{textAlign: 'center'}}>
                <br/>
                <CheckCircleTwoTone twoToneColor="#28a745" className="mb-3" style={{fontSize: '2rem'}}/>
                <h4>Please check your inbox!</h4>
                <p>If there is an account associated with {email} you will receive an email with a link to
                    reset your password.</p>
            </div>
        )
    }

    return (
        <LayoutAuth>
            <div className="auth-signin">
                <Link to={"/"}>
                    <div className="logoContainer">
                        <img
                            src={logoShort}
                            alt="Logo"
                            className="logo"
                        />
                    </div>
                </Link>
                <Tabs defaultActiveKey={activeTab} centered>
                    <TabPane tab={<h2 className="text-center halfwidth">Forgot Password</h2>} key="1">
                        <Card bordered={false}>
                            <p className="text-center my-4">To reset your password, please enter your email and follow
                                the instructions.</p>
                            {renderForgotPasswordSection()}
                            <div className="text-center">
                                <Link to={"/auth/signin"}>
                                    <Button type="link"
                                            className="mt-4"
                                            icon={<RollbackOutlined/>}
                                            disabled={loading}>
                                        Back to Sign In
                                    </Button>
                                </Link>
                            </div>
                        </Card>
                    </TabPane>
                </Tabs>
            </div>
        </LayoutAuth>
    );
}

export default PasswordForgotPage;
