import qs from "query-string";
import api from "helpers/apiService";
import { AuthData, AuthDataDeserializer } from "models/authData.interface";

export async function signin(values: {
  username: string;
  password: string;
}): Promise<AuthData> {
  const requestOptions = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  let data = {
    username: values.username,
    password: values.password,
    grant_type: "password",
    apiVersion: "3.3",
    appPlatform: "webapp",
    appName: "webapp",
    //uniqueId: 'D7406C96-F8CA-4F55-AED5-E0E9B77269CF',
  };

  let dataString = qs.stringify(data);

  const resp = await api().post(`/token`, dataString, requestOptions);
  return AuthDataDeserializer(resp.data);
}

export async function passwordForgot(email: string): Promise<AuthData> {
  const requestOptions = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  let data = {
    Email: email
  };
  let dataString = qs.stringify(data);

  const resp = await api().post(`/api/License/User/ForgotPassword/Request`, dataString, requestOptions);
  return AuthDataDeserializer(resp.data);
}

export async function passwordReset(values: any): Promise<AuthData> {
  const requestOptions = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };


  let data = {
    Email: values.email,
    Password: values.password,
    ConfirmPassword: values.password_confirmation,
    Code: values.token
  };

  let dataString = qs.stringify(data);

  const resp = await api().post(`/api/License/User/ForgotPassword/Reset`, dataString, requestOptions);
  return AuthDataDeserializer(resp.data);
}

export const authenticationService = {
  signin,
  passwordForgot,
  passwordReset
};
