import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {
    Row,
    Col,
    Divider,
    Button,
    Input,
    message,
    Form,
    Select, Switch
} from 'antd';
import {
    LoadingOutlined,
    CheckCircleOutlined,
    LeftOutlined,
    LoginOutlined,
    WarningOutlined
} from "@ant-design/icons/lib";
import {FiLock, FiMail} from "react-icons/fi";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import {RootState} from "common/store";
import {checkLicense, postLicense} from "modules/license/redux/licenseSlice";
import {LicenseCheck, NewLicense} from "modules/license/interfaces";
import {Company, User} from "models/user.interface";
import "./LicenseNew.scss";
import {unwrapResult} from "@reduxjs/toolkit";

interface IProps {
    routes: IRoute[];
}

const LicenseNew: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const [licenseForm] = Form.useForm();
    const [newLicenseData, setNewLicenseData] = useState<NewLicense>({} as NewLicense);
    const [step, setStep] = useState(1);
    const [isRenewal, setIsRenewal] = useState(false);
    const token: string = useSelector((state: RootState) => state.authentication.authData.token)
    const postLicenseStatus: string = useSelector((state: RootState) => state.license.postLicenseStatus)
    const user: User = useSelector((state: RootState) => state.user.me)
    const company: Company = useSelector((state: RootState) => state.user.company)
    const history = useHistory();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();

    useEffect(() => {
        const lpk = query.get("lpk");
        const token = query.get("token");
        if (!lpk || !token) {
            setStep(4);
            return;
        }
        const licenseCheck = {
            "LicensePackageKey": lpk,
            "Token": token,
        } as LicenseCheck;
        const check = async () => {
            const resultAction: any = await dispatch(
                checkLicense(licenseCheck)
            );
            unwrapResult(resultAction);
        }
        check().then(resp => {
            setStep(2)
        }).catch(e => {
            //message.error(e.message);
            setStep(4)
        });
    }, [])

    async function createLicense(values: any) {
        const newLicense: NewLicense = {
            ...values,
            Token: query.get("token")?.toString()
        };
        setNewLicenseData(newLicense);
        try {
            const resultAction: any = await dispatch(
                postLicense(newLicense)
            );
            unwrapResult(resultAction);
            message.success(token ? "New License Key added!" : "New account with License Key created!");
            token ? history.push("/license") : setStep(3);
        } catch (e) {
            message.error(e.message);
        }
    }

    function handleSwitchChange() {
        setIsRenewal(!isRenewal);
    }

    function renderContent() {
        if (step === 2) {
            return <Form
                id="detailsForm"
                form={licenseForm}
                layout="vertical"
                size="large"
                name="license"
                className="px-4 pb-5"
                initialValues={{
                    "LicensePackageKey": query.get("lpk") || undefined
                }}
                onFinish={(values: any) => {
                    createLicense(values);
                }}
            >
                {token &&
                <div className="text-center pt-4">
                    <p className="mb-1">You are logged in as</p>
                    <h2 className="font-bold mb-0">{company.companyLegalName}</h2>
                    <h4>{user.email}</h4>
                </div>
                }
                <Divider><h6 className="uppercase mt-2 text-gray-600">License Details</h6></Divider>
                <Form.Item
                    label="License Key"
                    name="LicensePackageKey"
                    rules={[{required: true, message: "Required field"}]}
                >
                    <Input
                    />
                </Form.Item>
                {!token && <div>
                    <Divider><h6 className="uppercase mt-2 text-gray-600">Company Data</h6></Divider>
                    <Form.Item
                        label="Company Account Name"
                        name="CompanyName"
                        rules={[{required: true, message: "Required field"}]}
                    >
                        <Input
                        />
                    </Form.Item>
                    <Form.Item
                        label="Company Legal Name"
                        name="CompanyLegalName"
                        rules={[{required: true, message: "Required field"}]}
                    >
                        <Input
                        />
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="Street"
                        rules={[{required: true, message: "Required field"}]}
                    >
                        <Input
                        />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col xs={8}>
                            <Form.Item
                                label="Postal Code"
                                name="PostalCode"
                                rules={[{required: true, message: "Required field"}]}
                            >
                                <Input
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={16}>
                            <Form.Item
                                label="City"
                                name="Locality"
                                rules={[{required: true, message: "Required field"}]}
                            >
                                <Input
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={12}>
                            <Form.Item
                                label="Region / State"
                                name="Region"
                                rules={[{required: true, message: "Required field"}]}
                            >
                                <Input
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Country"
                                name="CountryCode"
                                rules={[{required: true, message: 'Required field'}]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    placeholder="Please select a country">
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Åland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">Antigua and Barbuda</option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia, Plurinational State of</option>
                                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                    <option value="BA">Bosnia and Herzegovina</option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">British Indian Ocean Territory</option>
                                    <option value="BN">Brunei Darussalam</option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">Central African Republic</option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">Cocos (Keeling) Islands</option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">Congo, the Democratic Republic of the</option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Côte d'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curaçao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">Dominican Republic</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">Equatorial Guinea</option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">Falkland Islands (Malvinas)</option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">French Southern Territories</option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">Heard Island and McDonald Islands</option>
                                    <option value="VA">Holy See (Vatican City State)</option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">Iran, Islamic Republic of</option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KP">Korea, Democratic People's Republic of</option>
                                    <option value="KR">Korea, Republic of</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">Lao People's Democratic Republic</option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">Libya</option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">Micronesia, Federated States of</option>
                                    <option value="MD">Moldova, Republic of</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">Northern Mariana Islands</option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">Palestinian Territory, Occupied</option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Réunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">Russian Federation</option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthélemy</option>
                                    <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                                    <option value="KN">Saint Kitts and Nevis</option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin (French part)</option>
                                    <option value="PM">Saint Pierre and Miquelon</option>
                                    <option value="VC">Saint Vincent and the Grenadines</option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">Sao Tome and Principe</option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SX">Sint Maarten (Dutch part)</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">South Georgia and the South Sandwich Islands</option>
                                    <option value="SS">South Sudan</option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">Svalbard and Jan Mayen</option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">Syrian Arab Republic</option>
                                    <option value="TW">Taiwan, Province of China</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania, United Republic of</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">Trinidad and Tobago</option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">Turks and Caicos Islands</option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">United Arab Emirates</option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US">United States</option>
                                    <option value="UM">United States Minor Outlying Islands</option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela, Bolivarian Republic of</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">Virgin Islands, British</option>
                                    <option value="VI">Virgin Islands, U.S.</option>
                                    <option value="WF">Wallis and Futuna</option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        label="Phone"
                        name="Telephone"
                        rules={[{required: false}]}
                    >
                        <Input
                        />
                    </Form.Item>
                    <Divider plain><h6 className="uppercase mt-2 text-gray-600">Admin User Information</h6>
                    </Divider>
                    <Form.Item
                        label="Admin User Email"
                        extra={<p className="text-sm mb-0 mt-1">This will be your login username</p>}
                        name="AdminUserEmail"
                        rules={[{required: true, type: 'email', message: 'Please input a valid email'}]}
                    >
                        <Input
                            prefix={<FiMail/>}
                            placeholder="Email"
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-0"
                        label="Admin User Password"
                        name="AdminUserPassword"
                        rules={[{required: true, message: 'Please input a password'}]}
                    >
                        <Input.Password
                            prefix={<FiLock/>}
                            placeholder="Password"
                        />
                    </Form.Item>
                </div>}
                {token &&
                <div>
                    <Divider/>
                    <Row gutter={12}>
                        <Col xs={24} sm={12}>
                            <h4>Use this Key as renewal for another Key</h4>
                        </Col>
                        <Col xs={24} sm={12}>
                            <Switch defaultChecked={isRenewal} onChange={() => handleSwitchChange()} />
                        </Col>
                    </Row>
                </div>
                }
                {isRenewal &&
                <Form.Item
                    label="License Key to renew"
                    name="RenewedLicensePackageKey"
                    rules={[{required: true, message: "Required field"}]}
                >
                    <Input
                    />
                </Form.Item>
                }
                <Form.Item className="text-center mt-5">
                    <Row className="mt-5">
                        <Col xs={24} className="text-right">
                            <Button
                                loading={postLicenseStatus === 'loading'}
                                size="large"
                                htmlType="submit"
                                type="primary">
                                Confirm {isRenewal ? "Renewal" : "Activation"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        }
        if (step === 3) {
            return <div className="px-4 py-5 text-center">
                <CheckCircleOutlined style={{fontSize: '2rem'}} className="my-3"/>
                <h3>Company account created!</h3>
                <p>Your login email is: {newLicenseData.AdminUserEmail}</p>
                <Button className="mb-4" type="primary" size="large" icon={<LoginOutlined/>}
                        onClick={() => history.push("/auth/signin")}>Sign in</Button>
            </div>
        }
        if (step === 4) {
            return <div className="text-center p-5">
                <WarningOutlined style={{fontSize: '2rem'}} className="mb-2"/>
                <h4>Invalid request. Please check the link and try again. This License Key may have already been
                    used.</h4>
                {!token &&
                <Button className="my-4" type="primary" ghost size="large" icon={<LoginOutlined/>}
                        onClick={() => history.push("/auth/signin")}>Sign in</Button>}
            </div>
        }
        return <div className="text-center p-5">
            <LoadingOutlined style={{fontSize: '2rem'}} className="mb-2"/>
            <h4>Validating request...</h4>
        </div>
    }

    return (
        <LayoutCentered>
            <div className="license-new">
                <div className="license-header">
                    <Row align="middle" className="mb-0">
                        <Col xs={6}>
                            {token &&
                            <div onClick={() => history.push("/")}>
                                <Button icon={<LeftOutlined/>}
                                        type="link" className="inline-block">My Account</Button>
                            </div>}
                        </Col>
                        <Col xs={12} className="text-center">
                            <h3 className="ml-2 mb-0 inline-block font-bold">New License Activation</h3>
                        </Col>
                    </Row>
                </div>
                {renderContent()}
            </div>
        </LayoutCentered>
    );
};

export default LicenseNew;
