import React, {useEffect, useState} from "react";
import debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory} from "react-router-dom";
import {
    Row,
    Col,
    Divider,
    Avatar,
    Button,
    Spin,
    Input,
    AutoComplete,
    Badge,
    Table,
    Menu,
    Dropdown,
    Modal,
    Form, Popconfirm, message, Tag
} from 'antd';
import {
    LoadingOutlined,
    TeamOutlined,
    DeleteOutlined,
    LeftOutlined, UserOutlined, MoreOutlined, ReloadOutlined, UserAddOutlined, PlusOutlined,
} from "@ant-design/icons/lib";
import {FiChevronLeft, FiLock, FiMail} from "react-icons/fi";

import {IRoute} from "router/config";
import LayoutCentered from "layouts/LayoutCentered";
import servicesHelper from "helpers/services";
import {RootState} from "common/store";
import {deleteUser, getLicenses, getUsers, postLicense, postUser} from "modules/license/redux/licenseSlice";
import {License, NewUser} from "modules/license/interfaces";
import {User, UserDeserializer} from "models/user.interface";
import "./UserList.scss";
import moment from "moment";
import {unwrapResult} from "@reduxjs/toolkit";

interface IProps {
    routes: IRoute[];
}

const tableLoading = {
    spinning: true,
    indicator: <LoadingOutlined/>,
};

const UserList: React.FC<IProps> = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const [userForm] = Form.useForm();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [selectedLicenseKey, setSelectedLicenseKey] = useState<string>("");
    const currentUserId: string = useSelector((state: RootState) => state.authentication.authData.userId)
    const users: User[] = useSelector((state: RootState) => state.license.users)
    const getUsersStatus = useSelector((state: RootState) => state.license.getUsersStatus)
    const deleteUserStatus = useSelector((state: RootState) => state.license.deleteUserStatus)

    const columns = [
        {
            title: '',
            dataIndex: 'FirstName',
            render: (text: any, row: any, idx: any) => <div>
                <Row align="middle">
                    <Col xs={2}>
                        <Avatar>{row.FirstName?.charAt(0)}{row.LastName?.charAt(0)}</Avatar>
                    </Col>
                    <Col className="pl-3" xs={22}>
                        <h4 className="mb-0">{row.FirstName} {row.LastName}</h4>
                        <h5 className="mb-0">{row.Email}</h5>
                    </Col>
                </Row>
            </div>,
        },
        {
            title: 'Actions',
            dataIndex: 'Key',
            render: (text: any, row: any, idx: any) => <div className="text-center" style={{zIndex: 9999}}>
                {currentUserId !== row.UserId ? <Popconfirm
                    title="Are you sure? This action cannot be undone."
                    onConfirm={() => removeUser(row.UserId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button loading={deleteUserStatus === row.UserId} danger size="small" icon={<DeleteOutlined/>}>
                    </Button>
                </Popconfirm> : <Tag>Administrator</Tag>}
            </div>,
        },
    ];

    async function removeUser(userId: string) {
        try {
            const resultAction: any = await dispatch(
                deleteUser(userId)
            );
            unwrapResult(resultAction);
            message.success("User deleted");
            dispatch(getUsers());
        } catch (e) {
            message.error(e.message);
        }
    }

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch])

    return (
        <LayoutCentered>
            <div className="user-list">
                <div className="user-header">
                    <Row align="middle" className="mb-0">
                        <Col xs={6}>
                            <Link to={"/"}>
                                <Button icon={<LeftOutlined/>}
                                        type="link" className="inline-block">Home</Button>
                            </Link>
                        </Col>
                        <Col xs={12} className="text-center">
                            <h3 className="ml-2 mb-0 inline-block font-bold">Users</h3>
                        </Col>
                        <Col xs={6} className="text-right">
                            {/*<Link to={"/license/new"}>
                                <Button icon={<PlusOutlined/>}
                                        type="primary" ghost className="inline-block">New</Button>
                            </Link>*/}
                        </Col>
                    </Row>
                </div>
                <Table
                    rowClassName="cursor-pointer"
                    className="bg-white"
                    columns={columns}
                    pagination={false}
                    dataSource={users}
                    loading={getUsersStatus === 'loading' ? tableLoading : false}
                    bordered
                    rowKey="UserId"
                    showHeader={false}
                />
            </div>
        </LayoutCentered>
    );
};

export default UserList;
